import { use, useEffect, useState } from "react";
import lod_ from "lodash";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MDButton from "components/Basics/MDButton";
import { t } from "i18next";
import MappingContent from "./MappingContent";

const MappingDialog = ({ open, onClose, mapping: mappingProps, config, onChange }) => {
	const [mapping, setMapping] = useState(lod_.cloneDeep(mappingProps));

	const handleChangeMapping = newMapping => {
		setMapping(newMapping);
	};

	useEffect(() => {
		setMapping(lod_.cloneDeep(mappingProps));
	}, [open]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="xl"
			PaperProps={{
				style: {
					height: "90%"
				}
			}}
		>
			<DialogTitle>Mapping</DialogTitle>
			<DialogContent>
				<MappingContent
					mapping={mapping}
					config={config}
					handleChangeMapping={handleChangeMapping}
				/>
			</DialogContent>
			<DialogActions>
				<MDButton onClick={onClose} variant="outlined" color="info">
					{t("FORMS.cancel")}
				</MDButton>
				<MDButton
					onClick={() => {
						onChange(mapping);
						onClose();
					}}
					color="info"
					variant="contained"
				>
					{t("FORMS.validate")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
};

export default MappingDialog;
