/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unreachable */
/* eslint-disable prefer-destructuring */
import lod_ from "lodash";
import {
	CircularProgress,
	createTheme,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	Icon,
	IconButton,
	InputLabel,
	Menu,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Slider,
	ThemeProvider
} from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { socket } from "redux-react/middleware/ws";
import { useEffect, useState } from "react";
import { t } from "i18next";
import MDButton from "components/Basics/MDButton";

const TONALITY_LIST = [
	"cordial",
	"happily",
	"serious",
	"casual",
	"familiar",
	"formal",
	"polite",
	"professional"
];

/**
 * Right pannel for the answer type component
 * @param {*} param0
 * @returns
 */
const RightPannel = ({
	rightMenuPannel,
	setRightMenuPannel,
	alternatives,
	selectedAlternative,
	changeAlternativePath,
	name,
	description,
	channelType
}) => {
	const [generatingAnswer, setGeneratingAnswer] = useState(false);
	const [translatingAnswer, setTranslatingAnswer] = useState(false);
	const [translationMenu, setTranslationMenu] = useState({ open: null });

	const selectedLanguage = rightMenuPannel?.languages?.find(
		item => item.language === rightMenuPannel.language
	);

	const theme = createTheme({
		components: {
			MuiSlider: {
				styleOverrides: {
					rail: {
						color: "#ddd"
					},
					track: {
						color: "#007aff"
					},
					thumb: {
						color: "#007aff"
					}
				}
			}
		}
	});

	const [inputs, setInputs] = useState({
		emojisCount: {
			value: 2
		},
		answerTone: {
			value: "professional"
		},
		youForm: {
			value: "vouvoiement"
		},
		maximumWords: {
			value: 20
		},
		temperature: {
			value: 0.8
		}
	});

	/**
	 * Function to get all the languages that can be translated from the current language
	 * @param {Boolean} allLanguages if we want to translate all languages
	 * @returns Array of languages
	 */
	const getAllowedTranslationLanguages = (allLanguages = false) => {
		let currentAlternative =
			alternatives?.find((item, index) => index === selectedAlternative) ?? null;
		if (!currentAlternative) return [];

		let currentLanguage = rightMenuPannel.language;

		let translationKeys = Object.keys(currentAlternative);

		// allLanguages = true, return all languages, else do not take care about the current language
		// because we dont want to translate the current language into the current language
		if (!allLanguages) {
			translationKeys = translationKeys.filter(key => key !== currentLanguage);
		}

		if (lod_.isEmpty(translationKeys)) return [];

		let existingKeys = translationKeys.filter(
			key => currentAlternative[key]?.text && currentAlternative[key]?.text?.trim() !== ""
		);

		return existingKeys;
	};

	const getFormat = () => {
		switch (channelType) {
			case "review":
				return "PLAIN TEXT";
			case "ticket":
				return "HTML avec du style";
			default:
				return "PLAIN TEXT";
		}
	};

	/**
	 * Generate answer with LLM for X languages
	 * @param {[String]} languagesArray Array of languages
	 */
	const generateAnswerLlm = languagesArray => {
		setGeneratingAnswer(true);
		socket.emit("llmModule", {
			response: {
				eventName: "llmModule",
				alternative: rightMenuPannel.alternative,
				languages: languagesArray
			},
			request: {
				processCode: "generateAnswerType",
				inputs: {
					languages: languagesArray,
					name,
					description,
					emojisCount: inputs.emojisCount.value,
					answerTone: inputs.answerTone.value,
					youForm: inputs.youForm.value,
					maximumWords: inputs.maximumWords.value,
					format: getFormat()
				}
			}
		});
	};

	/**
	 * Translate X language from the choosen language
	 * @param {String} targetLanguage the target language
	 * @param {Boolean,} allLanguages if we want to translate all languages
	 */
	const translateAnswer = (targetLanguage, allLanguages = false) => {
		setTranslatingAnswer(true);

		let index = rightMenuPannel.alternative;
		let message = alternatives[index][targetLanguage].text;

		let languages = [rightMenuPannel.language];

		if (allLanguages) {
			let languagesArray = rightMenuPannel.languages.map(item => item.language);
			languagesArray = languagesArray.filter(item => item !== targetLanguage);
			languages = languagesArray;
		}

		socket.emit("llmModule", {
			response: {
				eventName: "llmModule",
				alternative: rightMenuPannel.alternative,
				languages
			},
			request: {
				processCode: "translator",
				inputs: {
					languages,
					targetLanguage,
					message
				}
			}
		});
	};

	/**
	 * Create the translation menu
	 * @returns JSX.Element
	 */
	const renderTranslationButtonMenu = () => {
		const allowedLanguagesKeys = getAllowedTranslationLanguages(translationMenu.allLanguages);
		const languages = (rightMenuPannel.languages ?? []).filter(item =>
			allowedLanguagesKeys.includes(item.language)
		);
		return languages.map((language, index) => {
			return (
				<MenuItem
					onClick={() => {
						setTranslationMenu({
							open: null,
							allLanguages: false
						});
						translateAnswer(language.language, translationMenu.allLanguages);
					}}
					key={index}
				>
					<MDBox display="flex" alignItems="center" flexDirection="row">
						<MDBox
							component="img"
							sx={{ ml: 0.5, mr: 0.5 }}
							src={language?.logoURL}
							alt=""
							style={{
								width: "20px",
								height: "20px",
								borderRadius: "50%"
							}}
						/>
						<MDTypography variant="body2" fontWeight="bold" fontSize="small">
							{language?.name}
						</MDTypography>
					</MDBox>
				</MenuItem>
			);
		});
	};

	/**
	 * Response from WS when generating an answer
	 * @param {success, response, result} Object return of WS
	 */
	const generateAnswerTypeResult = ({ success, response, result }) => {
		setGeneratingAnswer(false);
		setTranslatingAnswer(false);

		if (success) {
			let alternativeIndex = response.alternative;
			let languages = response.languages;

			for (let language of languages) {
				let value = lod_.get(result, language);
				let alternative = alternatives[alternativeIndex];
				alternative = lod_.get(alternative, language);

				if (lod_.isObject(value)) {
					if (channelType === "ticket") {
						// Replace subject only if the alternative is not using the last message subject
						if (!alternative?.useLastMessageSubject) {
							changeAlternativePath(alternativeIndex, language, "subject", value.subject);
						}
					}
					changeAlternativePath(alternativeIndex, language, "text", value.text);
				} else {
					changeAlternativePath(alternativeIndex, language, "text", value);
				}
			}
		}
	};

	/* Mount datas */
	useEffect(() => {
		setGeneratingAnswer(false);
		setTranslatingAnswer(false);

		/* WS */
		socket.on("llmModule", generateAnswerTypeResult);
		return () => {
			socket.off("llmModule", generateAnswerTypeResult);
		};
	}, [channelType]);

	/* Main component */
	return (
		<MDBox
			style={{
				transition: "all 0.5s ease",
				contentVisibility: "auto"
			}}
			sx={{
				top: 0,
				position: "absolute",
				right: rightMenuPannel.open ? 0 : "-25%",
				transform: rightMenuPannel.open ? "translateX(0)" : "translateX(100%)",
				width: "25%",
				height: "100%",
				transition: "all 0.5s ease",
				overflowY: "auto"
			}}
			bgColor="white"
			p={rightMenuPannel.open ? 1 : 0}
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
		>
			<MDBox flex="1">
				<MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
					<MDTypography variant="h5" sx={{ mt: 1 }}>
						{t("COMPONENT.ANSWERTYPE.createResponseWithAI")}
					</MDTypography>
					<IconButton onClick={() => setRightMenuPannel({ open: false })}>
						<Icon>close</Icon>
					</IconButton>
				</MDBox>
				<MDTypography variant="body2" sx={{ mb: 0.5 }}>
					{t("COMPONENT.ANSWERTYPE.usingNameAndDescription")}
				</MDTypography>
				<MDBox display="flex" flexDirection="row" alignItems="center" sx={{ mb: 0.5 }}>
					<MDTypography variant="body2" fontWeight="bold">
						{`Variante ${rightMenuPannel.alternative + 1}`}
					</MDTypography>
					{rightMenuPannel.language && (
						<>
							<MDTypography variant="body2" fontWeight="bold">
								{` - `}
							</MDTypography>
							{selectedLanguage?.logoURL && (
								<MDBox
									component="img"
									sx={{ ml: 0.5, mr: 0.5 }}
									src={selectedLanguage?.logoURL}
									alt=""
									style={{
										width: "20px",
										height: "20px",
										borderRadius: "50%"
									}}
								/>
							)}
							<MDTypography variant="body2" fontWeight="bold">
								{selectedLanguage?.name}
							</MDTypography>
						</>
					)}
				</MDBox>
				<MDBox
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
					p={1}
					shadow="md"
					borderRadius="md"
				>
					{/** Emojis */}
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<MDBox m={2}>
							<MDTypography variant="h6">{t("CONFIG.emojis")}</MDTypography>
							<MDBox>
								<MDBox mt={1} display="flex" alignItems="start">
									<ThemeProvider theme={theme}>
										<Slider
											value={inputs?.emojisCount?.value}
											marks={[
												{
													value: 0,
													label: "0"
												},
												{
													value: 1,
													label: "1"
												},
												{
													value: 2,
													label: "2"
												},
												{
													value: 3,
													label: "3"
												},
												{
													value: 4,
													label: "4"
												},
												{
													value: 5,
													label: "5"
												}
											]}
											min={0}
											max={5}
											onChange={(e, value) => {
												setInputs(prevState => ({
													...prevState,
													emojisCount: {
														value
													}
												}));
											}}
										/>
									</ThemeProvider>
								</MDBox>
							</MDBox>
						</MDBox>
					</Grid>

					{/** Tonalité */}
					<Grid item xs={12} sm={6} md={3} lg={3} sx={{ mt: 1 }}>
						<MDBox m={2}>
							<MDTypography variant="h6">{t("CONFIG.answerTone")}</MDTypography>
							<MDBox>
								<MDBox mt={1} display="flex" alignItems="start">
									<FormControl fullWidth>
										<InputLabel id="select-label">{t("CONFIG.answerTone")}</InputLabel>
										<Select
											labelId="select-label"
											id="select"
											label={t("CONFIG.answerTone")}
											value={inputs.answerTone?.value}
											onChange={e => {
												setInputs(prevState => ({
													...prevState,
													answerTone: {
														value: e.target.value
													}
												}));
											}}
										>
											{TONALITY_LIST.map((option, index) => (
												<MenuItem key={index} value={option}>
													{t(`CONFIG.tonalities.${option}`)}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</MDBox>
							</MDBox>
						</MDBox>
					</Grid>

					{/** Forme */}
					<Grid item xs={12} sm={6} md={3} lg={3} sx={{ mt: 1 }}>
						<MDBox m={2}>
							<MDTypography variant="h6">{t("CONFIG.youForm")}</MDTypography>
							<MDBox>
								<MDBox mt={1} flex="1" display="flex" alignItems="start">
									<RadioGroup
										aria-labelledby="demo-radio-buttons-group-label"
										value={inputs?.youForm?.value ?? "vouvoiement"}
										name="radio-buttons-group"
										row
									>
										<FormControlLabel
											value="vouvoiement"
											control={
												<Radio
													onClick={() => {
														setInputs(prevState => ({
															...prevState,
															youForm: {
																value: "vouvoiement"
															}
														}));
													}}
												/>
											}
											label={t("CONFIG.vouvoiement")}
										/>
										<FormControlLabel
											value="tutoiement"
											control={
												<Radio
													onClick={() => {
														setInputs(prevState => ({
															...prevState,
															youForm: {
																value: "tutoiement"
															}
														}));
													}}
												/>
											}
											label={t("CONFIG.tutoiement")}
										/>
									</RadioGroup>
								</MDBox>
							</MDBox>
						</MDBox>
					</Grid>

					{/** Response lenght */}
					<Grid item xs={12} sm={6} md={3} lg={3} sx={{ mt: 1 }}>
						<MDBox m={2}>
							<MDTypography variant="h6">{t("CONFIG.maximumWords")}</MDTypography>
							<MDBox>
								<MDBox mt={1}>
									<ThemeProvider theme={theme}>
										<Slider
											aria-label="Volume"
											value={inputs?.maximumWords?.value}
											step={10}
											min={20}
											max={100}
											onChange={(e, value) => {
												setInputs(prevState => ({
													...prevState,
													maximumWords: {
														value
													}
												}));
											}}
										/>
									</ThemeProvider>
									<MDBox display="flex" justifyContent="space-between">
										<MDTypography variant="caption">{t("CONFIG.short")}</MDTypography>
										<MDTypography variant="caption">{t("CONFIG.long")}</MDTypography>
									</MDBox>
								</MDBox>
							</MDBox>
						</MDBox>
					</Grid>

					{/** Temperature (creativity) */}
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<MDBox m={2}>
							<MDTypography variant="h6">{t("CONFIG.temperature")}</MDTypography>
							<MDBox>
								<MDBox mt={1}>
									<ThemeProvider theme={theme}>
										<Slider
											aria-label="Creativity"
											defaultValue={inputs?.temperature?.value}
											step={0.1}
											min={0.1}
											max={1.6}
											onChange={(e, value) => {
												setInputs(prevState => ({
													...prevState,
													temperature: {
														value
													}
												}));
											}}
										/>
									</ThemeProvider>
									<MDBox display="flex" justifyContent="space-between">
										<MDTypography variant="caption">{t("CONFIG.uncrea")}</MDTypography>
										<MDTypography variant="caption">{t("CONFIG.crea")}</MDTypography>
									</MDBox>
								</MDBox>
							</MDBox>
						</MDBox>
					</Grid>
				</MDBox>
			</MDBox>
			<MDBox>
				{/*
				 * Actions for selected language
				 */}
				{rightMenuPannel.language && (
					<>
						<MDTypography variant="caption" sx={{ mt: 1 }} fontWeight="bold">
							{t("COMPONENT.ANSWERTYPE.forThisLanguage")}
						</MDTypography>
						<MDBox
							display="flex"
							flexDirection="row"
							alignItems="stretch"
							justifyContent="space-between"
						>
							<MDButton
								variant="contained"
								color="info"
								sx={{ flex: 1, mr: 0.5 }}
								onClick={() => generateAnswerLlm([rightMenuPannel.language])}
								disabled={generatingAnswer || translatingAnswer}
							>
								{generatingAnswer ? (
									<CircularProgress color="white" size={15} thickness={5} sx={{ mr: 1 }} />
								) : (
									<Icon>auto_awesome</Icon>
								)}
								&nbsp;{t("COMPONENT.ANSWERTYPE.generateAnAnswer")}
							</MDButton>
							<MDButton
								variant="contained"
								color="info"
								sx={{ flex: 1, ml: 0.5 }}
								disabled={
									!getAllowedTranslationLanguages().length || translatingAnswer || generatingAnswer
								}
								onClick={e => setTranslationMenu({ open: e.currentTarget, allLanguages: false })}
							>
								{translatingAnswer ? (
									<CircularProgress color="white" size={15} thickness={5} sx={{ mr: 1 }} />
								) : (
									<Icon>translate</Icon>
								)}
								&nbsp;{t("COMPONENT.ANSWERTYPE.translateFrom")}
							</MDButton>
						</MDBox>
						<Divider />
					</>
				)}
				{/*
				 * Global actions for all languages
				 */}
				<MDTypography variant="caption" fontWeight="bold">
					{t("COMPONENT.ANSWERTYPE.forAllLanguages")}
				</MDTypography>
				<MDBox
					display="flex"
					flexDirection="row"
					alignItems="stretch"
					justifyContent="space-between"
				>
					<MDButton
						variant="contained"
						color="info"
						sx={{ flex: 1, mr: 0.5 }}
						onClick={() => {
							let languagesArray = rightMenuPannel.languages.map(item => item.language);
							generateAnswerLlm(languagesArray);
						}}
						disabled={generatingAnswer || translatingAnswer}
					>
						{generatingAnswer ? (
							<CircularProgress color="white" size={15} thickness={5} sx={{ mr: 1 }} />
						) : (
							<Icon>auto_awesome</Icon>
						)}
						&nbsp;{t("COMPONENT.ANSWERTYPE.generateAnAnswer")}
					</MDButton>
					<MDButton
						variant="contained"
						color="info"
						sx={{ flex: 1, ml: 0.5 }}
						disabled={
							!getAllowedTranslationLanguages(true).length || translatingAnswer || generatingAnswer
						}
						onClick={e => setTranslationMenu({ open: e.currentTarget, allLanguages: true })}
					>
						{translatingAnswer ? (
							<CircularProgress color="white" size={15} thickness={5} sx={{ mr: 1 }} />
						) : (
							<Icon>translate</Icon>
						)}
						&nbsp;{t("COMPONENT.ANSWERTYPE.translateFrom")}
					</MDButton>
				</MDBox>

				<Menu
					anchorEl={translationMenu.open}
					open={Boolean(translationMenu.open)}
					onClose={() => setTranslationMenu({ open: null, allLanguages: false })}
					// display on top middle
					anchorOrigin={{
						vertical: "top",
						horizontal: "center"
					}}
					// display on top middle
					transformOrigin={{
						vertical: "bottom",
						horizontal: "center"
					}}
				>
					<MenuItem disabled style={{ opacity: 1 }}>
						<MDTypography variant="caption">
							{t("COMPONENT.ANSWERTYPE.availableLanguages")}
						</MDTypography>
					</MenuItem>
					{renderTranslationButtonMenu()}
				</Menu>
			</MDBox>
		</MDBox>
	);
};

export default RightPannel;
