import lod_ from "lodash";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import { socket } from "redux-react/middleware/ws";
import MDBox from "components/Basics/MDBox";
import { LittleForm } from "components/Custom/LittleForm";
import MDTypography from "components/Basics/MDTypography";
import MDButton from "components/Basics/MDButton";
import { t } from "i18next";
import { formatTimestampToDate } from "helpers/utilities";

/**
 * Get shopify login url, that allow to request a delegation via OAuth2, with many parameters
 * @param {object} shopifyConfig The shopify platformConfig.CONFIG
 * @param {string} shop The name of the shopify shop
 * @param {object} state An free object that is passed through oauth to have some context on /oauth success call route on cm-shopify
 * @returns {string} The shopify login url
 */
function getShopifyLoginUrl(shopifyConfig, shop, state) {
	const { shopifyApp } = shopifyConfig || {};
	const { clientID, scope, redirectUri } = shopifyApp || {};

	const params = {
		client_id: clientID,
		scope,
		redirect_uri: redirectUri,
		state: JSON.stringify(state),
		"grant_options[]": "{access_mode}"
	};

	const loginRoute = `https://${shop}.myshopify.com/admin/oauth/authorize`;
	const loginUrl = `${loginRoute}?${new URLSearchParams(params).toString()}`;

	return loginUrl;
}

const ShopifyConfiguration = ({
	shopifyConfig,
	channelCode,
	config,
	onChange,
	setConfigPart,
	dictionary,
	configSkeleton
}) => {
	const user = useSelector(state => state.user);
	const profile = useSelector(selectCurrentProfile);

	const state = {
		ws: user.userID,
		action: "create",
		channelCode,
		assistantID: profile.assistantID
	};

	const { shop, accessToken } = config || {};
	const accessTokenValue = accessToken?.value;
	const shopifyTokenLoaded = !lod_.isNil(accessTokenValue) && accessTokenValue !== "";
	const showLoginButton = shop?.length > 3;

	const url = getShopifyLoginUrl(shopifyConfig, shop, state);

	const openAuthorizationIframe = () => {
		window.open(url, "_blank", "width=600,height=800").focus();
	};

	/**
	 * Result function of websocket event completeConfigChannelCreation
	 * Got when user creates a channel
	 * @param {*} { channel }
	 * @returns
	 */
	const completeConfigChannelCreation = ({ channel }) => {
		if (channel.code !== channelCode) {
			return;
		}

		if (channel?.config?.accessToken) {
			const update = {
				accessToken: channel.config.accessToken
			};

			setConfigPart(prev => {
				// Update only the page access token
				return {
					...prev,
					...update
				};
			});
		}
	};

	useEffect(() => {
		// Create socket listeners
		socket.on("completeConfigChannelCreation", completeConfigChannelCreation);

		return () => {
			socket.off("completeConfigChannelCreation", completeConfigChannelCreation);
		};
	}, []);

	return (
		<MDBox sx={{ mt: 4, flex: 1 }}>
			<MDBox display="flex" flexDirection="column" justifyContent="center">
				<MDTypography variant="h5" color="textPrimary" sx={{ mb: 2 }}>
					{shopifyTokenLoaded ? t("CHANNEL.SHFY.connected") : t("CHANNEL.SHFY.connectToShopify")}
				</MDTypography>
			</MDBox>

			<MDBox sx={{ mb: 2 }}>
				<LittleForm
					object={dictionary}
					metadatasSkeleton={configSkeleton}
					handleChange={(path, value) => {
						onChange(path, value);
					}}
				/>
			</MDBox>

			<MDBox display="flex" flexDirection="column" alignItems="center" justifyContent="center">
				{shopifyTokenLoaded && (
					<MDTypography variant="body" sx={{ mb: 2 }}>
						{`${t("CHANNEL.SHFY.connectedToShopify.part1")} ${formatTimestampToDate(accessToken?.generatedOn)} ${t("CHANNEL.SHFY.connectedToShopify.part2")} ${accessToken?.scope}. ${t("CHANNEL.SHFY.connectedToShopify.part3")}`}
					</MDTypography>
				)}

				{showLoginButton && (
					<MDButton color="info" onClick={openAuthorizationIframe}>
						<MDBox sx={{ ml: 2 }} color="white">
							{shopifyTokenLoaded
								? t("CHANNEL.SHFY.refreshAccountAccess")
								: t("CHANNEL.SHFY.connect")}
						</MDBox>
					</MDButton>
				)}
			</MDBox>
		</MDBox>
	);
};

export default ShopifyConfiguration;
