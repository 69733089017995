/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import SettingsActions from "redux-react/actions/settingsActions";
/**
 * Query a dictionary with promise
 * @param {string} code - Code of the dictionary
 * @returns {Promise} - Promise with the dictionary
 */
export async function queryDictionary(dispatch, code) {
	return new Promise((resolve, reject) => {
		dispatch(
			SettingsActions.getDictionary(code, res => {
				resolve(res.dictionary);
			})
		);
	});
}

/**
 * Query dictionaries from the API
 * @param {<string>} value
 * @returns {Promise} - Promise with the dictionaries
 */
export async function queryDictionaries(dispatch, codes) {
	const dictionaries = {};

	for (let codeDictionary of codes) {
		let dictionary = await queryDictionary(dispatch, codeDictionary);
		dictionaries[codeDictionary] = dictionary;
	}

	return dictionaries;
}

export function convertObjectToArray(obj) {
	if (!obj) return [];
	return Object.keys(obj).map(key => {
		return { code: key, ...obj[key] };
	});
}

/**
 * Convert path into dictionary path "context.name" => "context.items.name"
 * @param {*} path
 * @returns
 */
export function getDictionaryPath(path) {
	return path.replaceAll(".", ".items.");
}

/**
 * Get the label from a dictionary's item
 * @param {*} labels
 * @param {*} path
 * @param {*} language
 * @returns
 */
export function getLabel(labels, language = "fr") {
	let label = labels[language];
	if (!label) {
		label = labels[Object.keys(labels)[0]];
	}
	return label;
}
