import React, { useEffect, useCallback } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";

const validationSchema = Yup.object().shape({
	question: Yup.string().trim().required(t("FORMS.ERRORS.questionRequired")),
	options: Yup.array()
		.of(Yup.string().trim().required(t("FORMS.ERRORS.optionRequired")))
		.min(2, t("FORMS.ERRORS.atLeastTwoOptions"))
		.max(3, t("FORMS.ERRORS.maxThreeOptions"))
		.test("no-duplicates", t("FORMS.ERRORS.noDuplicateOptions"), options => {
			const trimmedOptions = options.map(opt => opt?.trim().toLowerCase());
			return new Set(trimmedOptions).size === trimmedOptions.length;
		})
});

const SurveyForm = ({ formData, onChangeForm, onValidate }) => {
	const initialValues = {
		question: formData.question || "",
		options: [
			formData.option1 || "",
			formData.option2 || "",
			...(formData.option3 ? [formData.option3] : [])
		].slice(0, 3)
	};

	const handleUpdateParent = useCallback(
		values => {
			const formattedValues = {
				question: values.question,
				option1: values.options[0] || "",
				option2: values.options[1] || "",
				option3: values.options[2] || ""
			};
			onChangeForm({ target: { name: "all", value: formattedValues } });
		},
		[onChangeForm]
	);

	const formik = useFormik({
		initialValues,
		validationSchema,
		validateOnChange: true,
		validateOnBlur: true,
		onSubmit: values => {
			handleUpdateParent(values);
			onValidate?.(true);
		}
	});

	const handleAddOption = () => {
		if (formik.values.options.length < 3) {
			const newOptions = [...formik.values.options, ""];
			formik.setFieldValue("options", newOptions);
			handleUpdateParent({ ...formik.values, options: newOptions });
		}
	};

	const handleDeleteOption = index => {
		if (formik.values.options.length > 2) {
			const newOptions = formik.values.options.filter((_, i) => i !== index);
			formik.setFieldValue("options", newOptions);
			handleUpdateParent({ ...formik.values, options: newOptions });
		}
	};

	useEffect(() => {
		onValidate?.(formik.isValid);
	}, [formik.isValid, onValidate]);

	return (
		<Container maxWidth="md">
			<Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 3 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label={t("LABELS.question")}
							name="question"
							value={formik.values.question}
							onChange={e => {
								formik.handleChange(e);
								handleUpdateParent({
									...formik.values,
									question: e.target.value
								});
							}}
							onBlur={formik.handleBlur}
							variant="outlined"
							required
							error={formik.touched.question && !!formik.errors.question}
							helperText={formik.touched.question && formik.errors.question}
							autoFocus
						/>
					</Grid>

					{formik.values.options.map((option, index) => (
						<Grid
							key={`option-${index}`}
							item
							xs={12}
							sx={{ display: "flex", alignItems: "center" }}
						>
							<TextField
								fullWidth
								label={`${t("LABELS.option")} ${index + 1}`}
								name={`options.${index}`}
								value={option}
								onChange={e => {
									const newOptions = [...formik.values.options];
									newOptions[index] = e.target.value;
									formik.setFieldValue("options", newOptions);
									handleUpdateParent({
										...formik.values,
										options: newOptions
									});
								}}
								onBlur={formik.handleBlur}
								variant="outlined"
								required={index < 2}
								error={
									formik.touched.options &&
									(typeof formik.errors.options === "string" || !!formik.errors.options?.[index])
								}
								helperText={
									formik.touched.options?.[index] &&
									((typeof formik.errors.options === "string" && formik.errors.options) ||
										formik.errors.options?.[index])
								}
								sx={{ mr: formik.values.options.length > 2 ? 1 : 0 }}
							/>
							{formik.values.options.length > 2 && (
								<IconButton color="default" onClick={() => handleDeleteOption(index)} sx={{ ml: 1 }}>
									<Delete />
								</IconButton>
							)}
						</Grid>
					))}

					{formik.values.options.length < 3 && (
						<Grid item xs={12}>
							<Button
								variant="outlined"
								startIcon={<Add />}
								onClick={handleAddOption}
								size="small"
								sx={{
									mt: 1,

									py: 0.25,
									fontSize: "0.60rem",
									color: "#1A73E8",
									padding: "6px"
								}}
							>
								{t("LABELS.addOption")}
							</Button>
						</Grid>
					)}
				</Grid>
			</Box>
		</Container>
	);
};

export default SurveyForm;
