import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Error from "@mui/icons-material/Error";
import Tooltip from "@mui/material/Tooltip";

import { fileTypeIcons } from "constants";

const getFileIcon = fileType => {
	if (!fileType) return fileTypeIcons.get("default");
	const matchingKey = [...fileTypeIcons.keys()].find(key => fileType.includes(key));
	return fileTypeIcons.get(matchingKey) || fileTypeIcons.get("default");
};

const extractFileData = data => {
	if (!data?.payload) return null;
	const { payload } = data.payload;
	if (payload.attachments?.length > 0) {
		const attachment = payload.attachments[0];
		return {
			url: attachment.url,
			mimeType: attachment.mimeType,
			name: attachment.name,
			isUploading: attachment.isUploading || false,
			uploadError: attachment.uploadError || false,
			key: attachment.key || null
		};
	}
	return data;
};

const FileItem = ({ file, onReselect }) => {
	// Removed onRemove prop
	const renderFileStatus = () => {
		if (file.isUploading) return <CircularProgress color="info" size={20} />;
		if (file.uploadError) return <Error color="error" />;
		return getFileIcon(file.mimeType);
	};

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				gap: 1,
				// border: "1px solid #e0e0e0",
				borderRadius: "4px",
				backgroundColor: "#f5f5f5",
				cursor: "pointer"
			}}
			onClick={onReselect}
		>
			{renderFileStatus()}
			<Tooltip title={file.name}>
				<Typography
					sx={{
						fontSize: "14px",
						maxWidth: "120px",
						whiteSpace: "nowrap",
						overflow: "hidden",
						textOverflow: "ellipsis"
					}}
				>
					{file.name}
				</Typography>
			</Tooltip>
		</Box>
	);
};

const File = ({ filesData, onReselect }) => {
	// Removed onRemoveFile prop
	const fileDataArray = Array.isArray(filesData) ? filesData : [filesData];
	const displayFiles = fileDataArray.map(extractFileData).filter(Boolean);

	return (
		<Box sx={{ mt: 1, display: "flex", flexWrap: "wrap", gap: 2 }}>
			{displayFiles.map((file, index) => (
				<FileItem key={index} file={file} onReselect={onReselect} />
			))}
		</Box>
	);
};

export default File;
