/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-curly-brace-presence */
import { Microsoft } from "@mui/icons-material";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import MDTypography from "components/Basics/MDTypography";
import { LittleForm } from "components/Custom/LittleForm";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { socket } from "redux-react/middleware/ws";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import { t } from "i18next";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

/**
 * Display infos when user has linked his microsoft account
 * @param {*} param0
 * @returns
 */
const MicrosoftMailConfiguration = ({ config, openAuthorizationIframe }) => {
	return (
		<MDBox sx={{ mb: 3 }}>
			<Table>
				<TableHead
					style={{
						display: "contents"
					}}
				>
					<TableRow>
						<TableCell>{t("CHANNEL.MICROSOFT.Email")}</TableCell>
						<TableCell colSpan={2}>{t("CHANNEL.MICROSOFT.expiresOn")}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell>{config?.office365?.userEmail}</TableCell>
						<TableCell>
							{new Date(config?.office365?.refreshToken?.expiresOn).toLocaleDateString()}
						</TableCell>
						<TableCell align="right">
							<MDButton color="info" onClick={openAuthorizationIframe}>
								<Microsoft />
								<MDBox sx={{ ml: 2 }} color="white">
									{t("CHANNEL.MICROSOFT.refreshAccountAccess")}
								</MDBox>
							</MDButton>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</MDBox>
	);
};

/**
 * Microsoft OAuth2 configuration
 * @param {*} param0
 * @returns
 */
const MicrosoftConfiguration = ({
	microsoftConfig,
	channelCode,
	config,
	onChange,
	setConfigPart,
	type,
	dictionary,
	configSkeleton
}) => {
	const user = useSelector(state => state.user);
	const profile = useSelector(selectCurrentProfile);

	const microsoftTokenLoaded = config.office365?.refreshToken?.token;

	// Infos for the microsoft authentification
	const { authUri, CLIENT_ID, redirectUri, graphScopes } = microsoftConfig || {};

	// State that will be passed to the microsoft authentification
	const state = {
		ws: user.userID,
		action: "create",
		type: type,
		channelCode: channelCode,
		assistantID: profile.assistantID
	};

	// Query params for the microsoft authentification
	const queryParams = new URLSearchParams({
		redirect_uri: redirectUri,
		client_id: CLIENT_ID,
		scope: graphScopes.join(" "),
		response_mode: "form_post",
		response_type: "code",
		prompt: "login",
		state: JSON.stringify(state)
	}).toString();

	// Url for the microsoft authentification
	const url = `${authUri}?${queryParams}`;

	// Open the google authentification in a new tab
	const openAuthorizationIframe = () => {
		window.open(url, "_blank", "width=500,height=600").focus();
	};

	/**
	 * Result function of websocket event completeConfigChannelCreation
	 * Got when user creates a channel
	 * @param {*} { channel }
	 * @returns
	 */
	const completeConfigChannelCreation = ({ channel }) => {
		if (channel.code !== channelCode) {
			return;
		}

		let channelConfig = channel.config;

		setConfigPart(prev => {
			return {
				...prev,
				type: channelConfig.type,
				office365: {
					limitFetchDate: prev.office365.limitFetchDate,
					...channelConfig.office365
				},
				options: prev.options
			};
		});
	};

	useEffect(() => {
		// Create socket listeners
		socket.on("completeConfigChannelCreation", completeConfigChannelCreation);

		return () => {
			socket.off("completeConfigChannelCreation", completeConfigChannelCreation);
		};
	}, []);

	return (
		<MDBox sx={{ mt: 4, flex: 1 }}>
			{/*
			 * When user is not connected to microsoft
			 */}
			{!microsoftTokenLoaded && (
				<MDBox
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					style={{
						height: "100%"
					}}
				>
					<MDTypography variant="h5" color="textPrimary" sx={{ mb: 2 }}>
						{t("CHANNEL.MICROSOFT.connectToMicrosoft")}
					</MDTypography>
					<MDButton color="info" onClick={openAuthorizationIframe}>
						<Microsoft />
						<MDBox sx={{ ml: 2 }} color="white">{`Se connecter`}</MDBox>
					</MDButton>
				</MDBox>
			)}
			{/*
			 * Connected to Microsoft
			 */}
			{microsoftTokenLoaded && (
				<MicrosoftMailConfiguration
					config={config}
					openAuthorizationIframe={openAuthorizationIframe}
				/>
			)}
			{/*
			 * Display configuratin
			 */}
			{microsoftTokenLoaded && (
				<MDBox>
					<LittleForm
						object={dictionary}
						metadatasSkeleton={configSkeleton}
						handleChange={(path, value) => {
							onChange(path, value);
						}}
					/>
				</MDBox>
			)}
		</MDBox>
	);
};

export default MicrosoftConfiguration;
