/* eslint-disable no-extra-boolean-cast */

import {
	Autocomplete,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Chip,
	FormControl,
	FormControlLabel,
	Grid,
	Icon,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Slider,
	Switch,
	ThemeProvider,
	createTheme
} from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import { queryDictionaries } from "helpers/dictionary";
import i18n from "i18n";
import lod_ from "lodash";
import DictionaryMenu from "pages/settings/filters/DictionaryMenu";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

/**
 * Generate reply component
 */
const GeneratereplyComponent = ({ onChange, channel, inputs }) => {
	const [anchorElOutput, setAnchorElOutput] = useState(null);
	const [dictionaries, setDictionaries] = useState({});
	const dispatch = useDispatch();

	const TONALITY_LIST = [
		"cordial",
		"happily",
		"serious",
		"casual",
		"familiar",
		"formal",
		"polite",
		"professional"
	];

	const theme = createTheme({
		components: {
			MuiSlider: {
				styleOverrides: {
					rail: {
						color: "#ddd"
					},
					track: {
						color: "#007aff"
					},
					thumb: {
						color: "#007aff"
					}
				}
			}
		}
	});

	const getDictionaries = async () => {
		const dictionariesList = await queryDictionaries(dispatch, ["context", "contact"]);
		setDictionaries(dictionariesList);
	};

	const setPath = e => {
		if (
			!lod_.isNil(inputs.conversationData?.value) &&
			lod_.isArray(inputs.conversationData?.value) &&
			!lod_.isEmpty(inputs.conversationData?.value)
		) {
			if (!inputs.conversationData?.value.includes(e)) {
				let newValue = lod_.cloneDeep(inputs.conversationData?.value);
				onChange(`conversationData.value`, [...newValue, e]);
			}
		} else {
			onChange(`conversationData.value`, [e]);
		}
	};

	useEffect(() => {
		getDictionaries();
	}, []);

	return (
		<MDBox sx={{ height: "100%", width: "100%" }} display="flex" justifyContent="center">
			<MDBox>
				<MDBox display="flex" alignItems="center" pb={2}>
					<MDBox
						sx={{
							height: "3rem",
							width: "3rem"
						}}
						mr={1}
					>
						<MDBox
							component="img"
							borderRadius="md"
							src={channel.logoURL}
							alt={channel.logoURL}
							sx={{
								width: "100%",
								height: "auto",
								objectFit: "contain"
							}}
						/>
					</MDBox>

					<MDTypography variant="h4">{`${i18n.t("CHANNEL.configureAnswer")} ${channel.name ? channel.name : ""}`}</MDTypography>
				</MDBox>
				{/* <MDBox display="flex" alignItems="stretch" mt={3}> */}
				<Grid container spacing={2}>
					{/** Emojis */}
					{/* <MDBox flex="1" mr={1}> */}
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<Card
							style={{
								height: "100%"
							}}
						>
							<CardHeader
								action={
									<MDBox>
										<span>{i18n.t("SETTINGS.RULES.active")}</span>

										<Switch
											onChange={(e, value) => {
												onChange(`emojisCount.active`, value);
											}}
											checked={Boolean(inputs.emojisCount?.active)}
										/>
									</MDBox>
								}
								title={<MDTypography variant="h6">{i18n.t("CONFIG.emojis")}</MDTypography>}
							/>
							<CardContent
								style={{
									height: "100%"
								}}
							>
								<MDBox mt={2}>
									<MDBox>
										<MDBox mt={1} display="flex" alignItems="start">
											<ThemeProvider theme={theme}>
												<Slider
													defaultValue={inputs?.emojisCount?.value}
													marks={[
														{
															value: 0,
															label: "0"
														},
														{
															value: 1,
															label: "1"
														},
														{
															value: 2,
															label: "2"
														},
														{
															value: 3,
															label: "3"
														},
														{
															value: 4,
															label: "4"
														},
														{
															value: 5,
															label: "5"
														}
													]}
													min={0}
													max={5}
													onChange={(e, value) => {
														onChange(`emojisCount.value`, value);
													}}
												/>
											</ThemeProvider>
										</MDBox>
									</MDBox>
								</MDBox>
							</CardContent>
							<CardActions>
								<MDBox
									display="flex"
									justifyContent="space-between"
									flexDirection="column"
									alignItems="left"
								>
									<MDBox>
										<span>{i18n.t("CONFIG.activeUser")}</span>

										<Switch
											checked={Boolean(inputs.emojisCount?.display)}
											disabled={!inputs.emojisCount?.active}
											onChange={(e, value) => {
												onChange(`emojisCount.display`, value);
											}}
										/>
									</MDBox>
								</MDBox>
							</CardActions>
						</Card>
					</Grid>
					{/* </MDBox> */}

					{/** Tonalité */}
					{/* <MDBox flex="1" mr={1} ml={1}> */}
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<Card
							style={{
								height: "100%"
							}}
						>
							<CardHeader
								action={
									<MDBox>
										<span>{i18n.t("SETTINGS.RULES.active")}</span>

										<Switch
											onChange={(e, value) => {
												onChange(`answerTone.active`, value);
											}}
											checked={Boolean(inputs.answerTone?.active)}
										/>
									</MDBox>
								}
								title={<MDTypography variant="h6">{i18n.t("CONFIG.answerTone")}</MDTypography>}
							/>
							<CardContent
								style={{
									height: "100%"
								}}
							>
								<MDBox mt={2}>
									<MDBox>
										<MDBox mt={1} display="flex" alignItems="start">
											<FormControl fullWidth>
												<InputLabel id="select-label">{i18n.t("CONFIG.answerTone")}</InputLabel>
												<Select
													labelId="select-label"
													id="select"
													label={i18n.t("CONFIG.answerTone")}
													value={inputs.answerTone?.value}
													onChange={e => {
														onChange(`answerTone.value`, e.target.value);
													}}
												>
													{TONALITY_LIST.map((option, index) => (
														<MenuItem key={index} value={i18n.t(`CONFIG.tonalities.${option}`)}>
															{i18n.t(`CONFIG.tonalities.${option}`)}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</MDBox>
									</MDBox>
								</MDBox>
							</CardContent>
							<CardActions>
								<MDBox
									display="flex"
									justifyContent="space-between"
									flexDirection="column"
									alignItems="left"
								>
									<MDBox>
										<span>{i18n.t("CONFIG.activeUser")}</span>

										<Switch
											onChange={(e, value) => {
												onChange(`answerTone.display`, value);
											}}
											checked={Boolean(inputs.answerTone?.display)}
											disabled={!inputs.answerTone?.active}
										/>
									</MDBox>
								</MDBox>
							</CardActions>
						</Card>
					</Grid>
					{/* </MDBox> */}

					{/** Forme */}
					{/* <MDBox flex="1" mr={1} ml={1}> */}
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<Card
							style={{
								height: "100%"
							}}
						>
							<CardHeader
								action={
									<MDBox>
										<span>{i18n.t("SETTINGS.RULES.active")}</span>

										<Switch
											onChange={(e, value) => {
												onChange(`youForm.active`, value);
											}}
											checked={Boolean(inputs.youForm?.active)}
										/>
									</MDBox>
								}
								title={<MDTypography variant="h6">{i18n.t("CONFIG.youForm")}</MDTypography>}
							/>
							<CardContent
								style={{
									height: "100%"
								}}
							>
								<MDBox mt={2}>
									<MDBox>
										<MDBox mt={1} flex="1" display="flex" alignItems="start">
											<RadioGroup
												aria-labelledby="demo-radio-buttons-group-label"
												defaultValue={inputs?.youForm?.value}
												name="radio-buttons-group"
												row
											>
												<FormControlLabel
													value="vouvoiement"
													control={
														<Radio
															onClick={() => {
																onChange(`youForm.value`, "vouvoiement");
															}}
														/>
													}
													label={i18n.t("CONFIG.vouvoiement")}
												/>
												<FormControlLabel
													value="tutoiement"
													control={
														<Radio
															onClick={() => {
																onChange(`youForm.value`, "tutoiement");
															}}
														/>
													}
													label={i18n.t("CONFIG.tutoiement")}
												/>
											</RadioGroup>
										</MDBox>
									</MDBox>
								</MDBox>
							</CardContent>
							<CardActions>
								<MDBox display="flex" flexDirection="column" alignItems="left">
									<MDBox>
										<span>{i18n.t("CONFIG.activeUser")}</span>

										<Switch
											onChange={(e, value) => {
												onChange(`youForm.display`, value);
											}}
											checked={Boolean(inputs.youForm?.display)}
											disabled={!inputs.youForm?.active}
										/>
									</MDBox>
								</MDBox>
							</CardActions>
						</Card>
					</Grid>
					{/* </MDBox> */}

					{/** Response lenght */}
					{/* <MDBox flex="1" mr={1} ml={1}> */}
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<Card
							style={{
								height: "100%"
							}}
						>
							<CardHeader
								action={
									<MDBox>
										<span>{i18n.t("SETTINGS.RULES.active")}</span>

										<Switch
											onChange={(e, value) => {
												onChange(`maximumWords.active`, value);
											}}
											checked={Boolean(inputs.maximumWords?.active)}
										/>
									</MDBox>
								}
								title={<MDTypography variant="h6">{i18n.t("CONFIG.maximumWords")}</MDTypography>}
							/>
							<CardContent
								style={{
									height: "100%"
								}}
							>
								<MDBox mt={2}>
									<MDBox>
										<MDBox mt={1}>
											<ThemeProvider theme={theme}>
												<Slider
													aria-label="Volume"
													defaultValue={inputs?.maximumWords?.value}
													step={10}
													min={20}
													max={100}
													onChange={(e, value) => {
														onChange(`maximumWords.value`, value);
													}}
												/>
											</ThemeProvider>
											<MDBox display="flex" justifyContent="space-between">
												<MDTypography variant="caption">{i18n.t("CONFIG.short")}</MDTypography>
												<MDTypography variant="caption">{i18n.t("CONFIG.long")}</MDTypography>
											</MDBox>
										</MDBox>
									</MDBox>
								</MDBox>
							</CardContent>
							<CardActions>
								<MDBox display="flex" flexDirection="column" alignItems="left">
									<MDBox>
										<span>{i18n.t("CONFIG.activeUser")}</span>

										<Switch
											onChange={(e, value) => {
												onChange(`maximumWords.display`, value);
											}}
											checked={Boolean(inputs.maximumWords?.display)}
											disabled={!inputs.maximumWords?.active}
										/>
									</MDBox>
								</MDBox>
							</CardActions>
						</Card>
					</Grid>
					{/* </MDBox> */}

					{/** Temperature */}
					{/* <MDBox flex="1" ml={1}> */}
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<Card
							style={{
								height: "100%"
							}}
						>
							<CardHeader
								action={
									<MDBox>
										<span>{i18n.t("SETTINGS.RULES.active")}</span>

										<Switch
											onChange={(e, value) => {
												onChange(`temperature.active`, value);
											}}
											checked={Boolean(inputs.temperature?.active)}
										/>
									</MDBox>
								}
								title={<MDTypography variant="h6">{i18n.t("CONFIG.temperature")}</MDTypography>}
							/>
							<CardContent
								style={{
									height: "100%"
								}}
							>
								<MDBox mt={2}>
									<MDBox>
										<MDBox mt={1}>
											<ThemeProvider theme={theme}>
												<Slider
													aria-label="Volume"
													defaultValue={inputs?.temperature?.value}
													step={0.1}
													min={0}
													max={2}
													valueLabelDisplay="auto"
													onChange={(e, value) => {
														onChange(`temperature.value`, value);
													}}
												/>
											</ThemeProvider>
											<MDBox display="flex" justifyContent="space-between">
												<MDTypography variant="caption">{i18n.t("CONFIG.uncrea")}</MDTypography>
												<MDTypography variant="caption">{i18n.t("CONFIG.crea")}</MDTypography>
											</MDBox>
										</MDBox>
									</MDBox>
								</MDBox>
							</CardContent>
							<CardActions>
								<MDBox display="flex" flexDirection="column" alignItems="left">
									<MDBox>
										<span>{i18n.t("CONFIG.activeUser")}</span>

										<Switch
											onChange={(e, value) => {
												onChange(`temperature.display`, value);
											}}
											checked={Boolean(inputs.temperature?.display)}
											disabled={!inputs.temperature?.active}
										/>
									</MDBox>
								</MDBox>
							</CardActions>
						</Card>
					</Grid>
					{/* </MDBox> */}

					{/** Language */}
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<Card
							style={{
								height: "100%"
							}}
						>
							<CardHeader
								action={
									<MDBox>
										<span>{i18n.t("SETTINGS.RULES.active")}</span>

										<Switch
											onChange={(e, value) => {
												onChange(`translationAnswer.active`, value);
											}}
											checked={Boolean(inputs.translationAnswer?.active)}
										/>
									</MDBox>
								}
								title={
									<MDTypography variant="h6">{i18n.t("CONFIG.translationAnswer")}</MDTypography>
								}
							/>
							<CardContent
								style={{
									height: "100%"
								}}
							></CardContent>
							<CardActions>
								<MDBox display="flex" flexDirection="column" alignItems="left">
									<MDBox>
										<span>{i18n.t("CONFIG.activeUser")}</span>

										<Switch
											onChange={(e, value) => {
												onChange(`translationAnswer.display`, value);
											}}
											checked={Boolean(inputs.translationAnswer?.display)}
											disabled={!inputs.translationAnswer?.active}
										/>
									</MDBox>
								</MDBox>
							</CardActions>
						</Card>
					</Grid>

					{/** AnswerItem */}
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<Card
							style={{
								height: "100%"
							}}
						>
							<CardHeader
								action={
									<MDBox>
										<span>{i18n.t("SETTINGS.RULES.active")}</span>

										<Switch
											onChange={(e, value) => {
												onChange(`recommendedResponseAnswer.active`, value);
											}}
											checked={Boolean(inputs.recommendedResponseAnswer?.active)}
										/>
									</MDBox>
								}
								title={
									<MDTypography variant="h6">
										{i18n.t("CONFIG.recommendedResponseAnswer")}
									</MDTypography>
								}
							/>
							<CardContent
								style={{
									height: "100%"
								}}
							></CardContent>
							<CardActions>
								<MDBox display="flex" flexDirection="column" alignItems="left">
									<MDBox>
										<span>{i18n.t("CONFIG.activeUser")}</span>

										<Switch
											onChange={(e, value) => {
												onChange(`recommendedResponseAnswer.display`, value);
											}}
											checked={Boolean(inputs.recommendedResponseAnswer?.display)}
											disabled={!inputs.recommendedResponseAnswer?.active}
										/>
									</MDBox>
								</MDBox>
							</CardActions>
						</Card>
					</Grid>

					{/** DATA CONV */}
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<Card
							style={{
								height: "100%"
							}}
						>
							<CardHeader
								action={
									<MDBox>
										<span>{i18n.t("SETTINGS.RULES.active")}</span>

										<Switch
											onChange={(e, value) => {
												onChange(`conversationData.active`, value);
											}}
											checked={Boolean(inputs.conversationData?.active)}
										/>
									</MDBox>
								}
								title={<MDTypography variant="h6">{i18n.t("CONFIG.conversationData")}</MDTypography>}
							/>
							<CardContent
								style={{
									height: "100%"
								}}
							>
								<MDBox>
									<MDBox>
										<MDBox mt={1} display="flex" alignItems="start">
											<MDBox display="flex" alignItems="center" style={{ width: "100%" }}>
												<Autocomplete
													fullWidth
													value={
														lod_.isArray(inputs.conversationData?.value)
															? inputs.conversationData?.value
															: []
													}
													onChange={(e, newValue) => {
														onChange(`conversationData.value`, newValue);
													}}
													disabled={inputs.conversationData?.active === false}
													multiple
													id="tags-filled"
													options={[]}
													freeSolo
													renderTags={(tags, getTagProps) => {
														return tags.map((option, index) => {
															return <Chip label={option} {...getTagProps({ index })} key={index} />;
														});
													}}
													renderInput={params => <MDInput {...params} />}
												/>
												{inputs.conversationData?.active === true && (
													<MDBox
														pl={1}
														onClick={e => setAnchorElOutput(e.target)}
														style={{ cursor: "pointer" }}
													>
														<Icon fontSize="small">add</Icon>
													</MDBox>
												)}
											</MDBox>
											<DictionaryMenu
												placement="right"
												dictionary={dictionaries}
												anchorEl={anchorElOutput}
												handleInsertText={e => {
													setAnchorElOutput(null);
													setPath(e);
												}}
												handleClose={() => setAnchorElOutput(null)}
											/>
										</MDBox>
									</MDBox>
								</MDBox>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				{/* </MDBox> */}
			</MDBox>
		</MDBox>
	);
};

export default GeneratereplyComponent;
