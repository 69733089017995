import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	stuckAction: {
		status: false,
		cancel: () => {},
		save: () => {},
		next: () => {},
		openDialog: false
	},
	disableRouting: false,
	changeRoute: false
};

export const error = initialState;

const slice = createSlice({
	name: "application",
	initialState,
	reducers: {
		setDisableRouting: (state, action) => {
			state.disableRouting = true;
		},
		setEnableRouting: (state, action) => {
			state.disableRouting = false;
		},
		changeRouteHandle: (state, action) => {
			state.changeRoute = action.payload;
		},
		setStuckAction: (state, action) => {
			state.stuckAction = action.payload;
		},
		resetStuckAction: state => {
			state.stuckAction = initialState.stuckAction;
		},
		closeStuckDialog: state => {
			state.stuckAction.openDialog = false;
		},
		openStuckDialog: (state, action) => {
			state.stuckAction.openDialog = true;
			state.stuckAction.next = action.payload;
		}
	}
});

export const isRoutingDisabled = state => state.application.disableRouting;

export const getStuckAction = state => state.application.stuckAction;

export const {
	setDisableRouting,
	setEnableRouting,
	changeRouteHandle,
	setStuckAction,
	resetStuckAction,
	closeStuckDialog,
	openStuckDialog
} = slice.actions;

export default slice.reducer;
