import React, { useEffect } from "react";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import { useFormik } from "formik";
import { t } from "i18next";
import * as Yup from "yup";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";

import "react-phone-number-input/style.css";

// Replace the PhoneTextField styled component with:
const PhoneInputWrapper = styled("div")(({ theme, error }) => ({
	"& .PhoneInput": {
		height: "43px",
		display: "flex",
		alignItems: "center",
		borderRadius: theme.shape.borderRadius,
		border: error ? `1px solid ${theme.palette.error.main}` : `1px solid rgba(0, 0, 0, 0.23)`, // Default MUI border color
		padding: "0 14px",
		"&:hover": {
			borderColor: error ? theme.palette.error.main : "#1A73E8"
		},

		"&:focus-within": {
			borderColor: error ? theme.palette.error.main : "#1A73E8",
			borderWidth: "2px",
			padding: "0 13px"
		}
	},
	"& .PhoneInputInput": {
		border: "none",
		outline: "none",
		fontSize: "1rem",
		fontFamily: theme.typography.fontFamily,
		background: "transparent",
		flex: 1,
		height: "100%",
		// Match TextField's padding
		padding: "16.5px 14px"
	}
}));

const ContactForm = ({ formData, onChangeForm, onUpdateValidateForm }) => {
	// Validation schema remains the same
	const validationSchema = Yup.object({
		firstName: Yup.string().trim().required(t("FORMS.ERRORS.isRequired")),
		lastName: Yup.string().trim().required(t("FORMS.ERRORS.isRequired")),
		email: Yup.string().email(t("FORMS.invalidEmail")),
		phone: Yup.string().test("is-valid-phone", t("FORMS.invalidPhone"), value =>
			value ? isValidPhoneNumber(value) : true
		),
		company: Yup.string(),
		street: Yup.string(),
		city: Yup.string(),
		state: Yup.string(),
		zip: Yup.string(),
		country: Yup.string()
	});

	const formik = useFormik({
		initialValues: formData,
		validationSchema,
		validateOnMount: true
	});

	const handleChange = event => {
		formik.handleChange(event);
		onChangeForm(event);
	};

	useEffect(() => {
		onUpdateValidateForm(formik.isValid);
	}, [formik.isValid]);

	return (
		<Container maxWidth="md">
			<Box
				component="form"
				sx={{
					mt: 4,
					display: "flex",
					flexDirection: "column",
					gap: 3
				}}
			>
				<Grid container spacing={2}>
					<Grid size={{ xs: 12, sm: 6 }}>
						<TextField
							fullWidth
							label={t("LABELS.firstName")}
							name="firstName"
							value={formik.values.firstName}
							onChange={handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.firstName && Boolean(formik.errors.firstName)}
							helperText={formik.touched.firstName && formik.errors.firstName}
							variant="outlined"
							required
							autoFocus
						/>
					</Grid>
					<Grid size={{ xs: 12, sm: 6 }}>
						<TextField
							fullWidth
							label={t("LABELS.lastName")}
							name="lastName"
							value={formik.values.lastName}
							onChange={handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.lastName && Boolean(formik.errors.lastName)}
							helperText={formik.touched.lastName && formik.errors.lastName}
							variant="outlined"
							required
						/>
					</Grid>
				</Grid>

				<Grid container spacing={2}>
					<Grid size={{ xs: 12, sm: 6 }}>
						<TextField
							fullWidth
							label={t("LABELS.email")}
							name="email"
							type="email"
							value={formik.values.email}
							onChange={handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.email && Boolean(formik.errors.email)}
							helperText={formik.touched.email && formik.errors.email}
							variant="outlined"
						/>
					</Grid>
					<Grid size={{ xs: 12, sm: 6 }}>
						<PhoneInputWrapper error={formik.touched.phone && Boolean(formik.errors.phone)}>
							<PhoneInput
								international
								defaultCountry="FR"
								value={formik.values.phone}
								onChange={value => {
									formik.setFieldValue("phone", value);
									handleChange({
										target: {
											name: "phone",
											value
										}
									});
								}}
								onBlur={() => formik.setFieldTouched("phone", true)}
								placeholder={t("LABELS.phone")}
								className="phone-input"
							/>
						</PhoneInputWrapper>
						{formik.touched.phone && formik.errors.phone && (
							<Box
								sx={{
									color: "error.main",
									fontSize: "0.75rem",
									mt: 0.5,
									ml: 1.75
								}}
							>
								{formik.errors.phone}
							</Box>
						)}
					</Grid>
				</Grid>

				{/* Rest of the form remains the same */}
				<TextField
					fullWidth
					label={t("LABELS.company")}
					name="company"
					value={formik.values.company}
					onChange={handleChange}
					onBlur={formik.handleBlur}
					variant="outlined"
				/>

				<TextField
					fullWidth
					label={t("LABELS.streetAdress")}
					name="street"
					value={formik.values.street}
					onChange={handleChange}
					onBlur={formik.handleBlur}
					variant="outlined"
				/>

				<Grid container spacing={2}>
					<Grid size={{ xs: 12, sm: 4 }}>
						<TextField
							fullWidth
							label={t("LABELS.city")}
							name="city"
							value={formik.values.city}
							onChange={handleChange}
							onBlur={formik.handleBlur}
							variant="outlined"
						/>
					</Grid>
					<Grid size={{ xs: 12, sm: 4 }}>
						<TextField
							fullWidth
							label={t("LABELS.state")}
							name="state"
							value={formik.values.state}
							onChange={handleChange}
							onBlur={formik.handleBlur}
							variant="outlined"
						/>
					</Grid>
					<Grid size={{ xs: 12, sm: 4 }}>
						<TextField
							fullWidth
							label={t("LABELS.zipCode")}
							name="zip"
							value={formik.values.zip}
							onChange={handleChange}
							onBlur={formik.handleBlur}
							variant="outlined"
						/>
					</Grid>
				</Grid>

				<TextField
					fullWidth
					label={t("LABELS.country")}
					name="country"
					value={formik.values.country}
					onChange={handleChange}
					onBlur={formik.handleBlur}
					variant="outlined"
				/>
			</Box>
		</Container>
	);
};

export default ContactForm;
