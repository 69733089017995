import { Dialog, Icon } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import MDTypography from "components/Basics/MDTypography";
import { t } from "i18next";
import { useSelector, useDispatch } from "react-redux";
import {
	resetStuckAction,
	getStuckAction,
	closeStuckDialog
} from "redux-react/reducers/applicationReducer";

/**
 * Show a dialog when user try to leave a page with unsaved data
 * It can be used everywhere in the application
 * due to the use of redux state
 *
 * use it with the following actions:
 * - openStuckDialog -> open the dialog
 * - closeStuckDialog -> close the dialog
 * - setStuckAction -> set the action to do when user click on save
 * - resetStuckAction -> reset the action
 *
 * @returns {JSX.Element} - Unsaved dialog
 */
const UnsavedDialog = () => {
	const stuck = useSelector(getStuckAction);
	const dispatch = useDispatch();

	// Close the dialog
	const onClose = () => {
		dispatch(closeStuckDialog());
	};

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={stuck.openDialog}
			disableBackdropClick
			disableEscapeKeyDown
			style={{
				zIndex: 9999
			}}
		>
			<MDBox p={3}>
				<MDTypography variant="h4">{t("SETTINGS.dialogSave.title")}</MDTypography>
				<MDBox mt={2}>
					<MDTypography variant="body2">{t("SETTINGS.dialogSave.message")}</MDTypography>
				</MDBox>
				<MDBox mt={2} display="flex" justifyContent="space-between">
					<MDButton
						onClick={() => {
							onClose();
						}}
					>
						{t("SETTINGS.cancel")}
					</MDButton>

					<MDBox display="flex">
						<MDBox mr={1}>
							<MDButton
								variant="gradient"
								color="error"
								onClick={() => {
									// Reset the stuck action
									dispatch(resetStuckAction());
									// Cancel action & go to the next step
									const actionCancel = stuck.cancel;
									const actionNext = stuck.next;
									actionCancel();
									actionNext();
								}}
							>
								<Icon>delete</Icon>&nbsp;{t("SETTINGS.dialogSave.dontSave")}
							</MDButton>
						</MDBox>

						<MDButton
							variant="gradient"
							color="info"
							onClick={() => {
								// Reset the stuck action
								dispatch(resetStuckAction());
								// Save action & go to the next step
								const actionSave = stuck.save;
								const actionNext = stuck.next;
								actionSave();
								actionNext();
							}}
						>
							<Icon>save</Icon>&nbsp;{t("SETTINGS.dialogSave.save")}
						</MDButton>
					</MDBox>
				</MDBox>
			</MDBox>
		</Dialog>
	);
};

export default UnsavedDialog;
