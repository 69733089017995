import React, { useState, useEffect } from "react";
import { t } from "i18next";

import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { TextField, Autocomplete } from "@mui/material";
import { REACT_GOOGLE_MAP_API_KEY } from "../../../constants/index";

const defaultPosition = {
	lat: 48.8566, // Default to Paris
	lng: 2.3522,
	name: "Paris, France",
	address: "Paris, France"
};

const SearchBox = ({ onPlaceSelected, onResetPosition }) => {
	const [inputValue, setInputValue] = useState("");
	const [options, setOptions] = useState([]);

	useEffect(() => {
		if (!window.google || !window.google.maps || !window.google.maps.places) {
			return;
		}

		const autocompleteService = new window.google.maps.places.AutocompleteService();

		if (inputValue.length) {
			autocompleteService.getPlacePredictions({ input: inputValue }, (predictions, status) => {
				if (status === window.google.maps.places.PlacesServiceStatus.OK) {
					setOptions(predictions || []);
				} else {
					setOptions([]);
				}
			});
		} else {
			setOptions([]);
		}
	}, [inputValue]);

	const handleSelect = (event, newValue) => {
		if (!newValue) return;

		const placesService = new window.google.maps.places.PlacesService(document.createElement("div"));

		placesService.getDetails({ placeId: newValue.place_id }, (place, status) => {
			if (status === window.google.maps.places.PlacesServiceStatus.OK && place.geometry) {
				const newPosition = {
					lat: place.geometry.location.lat(),
					lng: place.geometry.location.lng(),
					name: place.name || place.formatted_address,
					address: place.formatted_address || place.name
				};

				onPlaceSelected?.(newPosition);
			}
		});
	};

	return (
		<Autocomplete
			options={options}
			getOptionLabel={option => option.description || ""}
			onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
			onChange={handleSelect}
			disablePortal
			renderInput={params => (
				<TextField
					{...params}
					sx={{
						"& .MuiOutlinedInput-root .MuiAutocomplete-input": {
							padding: "0.5px 0px 4.5px 1px"
						}
					}}
					label={t("COMPONENT.ANSWERTYPE.searchLocation")}
				/>
			)}
			sx={{ width: 300, marginBottom: 2 }}
			//	onClose={onResetPosition}
		/>
	);
};

const GoogleMapComponent = ({
	onChangePosition,
	position = defaultPosition,
	isHiddenSearch = false
}) => {
	const handleDragEnd = event => {
		if (event.latLng) {
			const geocoder = new window.google.maps.Geocoder();
			const newPosition = {
				lat: event.latLng.lat(),
				lng: event.latLng.lng()
			};

			geocoder.geocode({ location: newPosition }, (results, status) => {
				if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
					onChangePosition?.({
						...newPosition,
						address: results[0].formatted_address,
						name: results[0].formatted_address
					});
				} else {
					onChangePosition?.(newPosition);
				}
			});
		}
	};

	const handlePlaceSelected = newPosition => {
		onChangePosition?.(newPosition);
	};

	return (
		<APIProvider apiKey={REACT_GOOGLE_MAP_API_KEY} libraries={["places", "geocoding"]}>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "100%",
					height: "100%",
					padding: "20px",
					boxSizing: "border-box"
				}}
			>
				{!isHiddenSearch && (
					<SearchBox
						onPlaceSelected={handlePlaceSelected}
						//	onResetPosition={() => handlePlaceSelected(defaultPosition)}
					/>
				)}
				<Map
					style={{
						width: "100%",
						height: "100%",
						borderRadius: "8px",
						border: "1px solid #ddd"
					}}
					defaultZoom={12}
					center={{ lat: position.lat, lng: position.lng }}
					gestureHandling={"greedy"}
					disableDefaultUI={false}
				>
					<Marker
						position={{ lat: position.lat, lng: position.lng }}
						draggable={true}
						onDragEnd={handleDragEnd}
					/>
				</Map>
			</div>
		</APIProvider>
	);
};

export default GoogleMapComponent;
