/* eslint-disable no-plusplus */
import lod_ from "lodash";
import { t } from "i18next";
import { useEffect, useState } from "react";
import MDBox from "components/Basics/MDBox";
import { Icon, IconButton, TableCell } from "@mui/material";
import DictionaryMenu from "pages/settings/filters/DictionaryMenu";
import MDInput from "components/Basics/MDInput";

const RowMapping = ({ dictionaries, path, value, onChangePath, onChangeValue, deleteRow }) => {
	const [anchorElInput, setAnchorElInput] = useState(null);

	/**
	 * Get the path's label for a dictionary
	 * @param {*} dictionary
	 * @param {*} path
	 * @returns
	 */
	const getPath = (dictionary, path) => {
		if (!path) {
			return null;
		}

		const parts = path.split(".");

		const result = [];
		let current = "";

		for (let i = 0; i < parts.length; i++) {
			current += parts[i];
			result.push(current);
			current += ".items.";
		}

		let stringPath = "";

		for (let i = 0; i < result.length; i++) {
			stringPath += " " + lod_.get(dictionary, result[i] + ".label.fr") + " /" || "";
		}

		stringPath = stringPath.slice(0, -1);
		return stringPath;
	};

	const [temporaryPath, setTemporaryPath] = useState(path);

	const onChange = inputValue => {
		inputValue = inputValue.replaceAll(" ", "");
		inputValue = inputValue.replaceAll(/\.{2,}/g, ".");

		setTemporaryPath(inputValue);

		let newPath = lod_.cloneDeep(inputValue);

		// if temporaryPath finish with a dot, remove it
		if (newPath.endsWith(".")) {
			newPath = newPath.slice(0, -1);
		}

		onChangePath(path, newPath, value);
	};

	return (
		<MDBox display="flex" flexDirection="row" alignItems="center" style={{ width: "100%" }}>
			<TableCell style={{ border: "none" }} width="50%">
				<MDInput fullWidth value={temporaryPath} onChange={e => onChange(e.target.value)} />
			</TableCell>
			{/* Mid cell */}
			<TableCell style={{ border: "none" }}>
				<MDBox display="flex" flexDirection="row" justifyContent="center" alignItems="center">
					<Icon color="success" fontSize="medium">
						arrow_backward
					</Icon>
				</MDBox>
			</TableCell>
			{/* Input */}
			<TableCell style={{ border: "none" }} width="50%">
				<MDBox
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="stretch"
				>
					<MDBox
						flex="1"
						className="boxInputStyle"
						borderRadius="md"
						style={{
							borderRadius: "0.375rem 0 0 0.375rem"
						}}
					>
						{getPath(dictionaries, value)}
					</MDBox>
					<MDBox
						className="endButtonboxInputStyle"
						display="flex"
						justifyContent="center"
						alignItems="center"
						bgColor="light"
						onClick={e => setAnchorElInput(e.target)}
					>
						<Icon fontSize="small">menu</Icon>
					</MDBox>
					<DictionaryMenu
						placement="right"
						dictionary={dictionaries}
						anchorEl={anchorElInput}
						handleInsertText={e => {
							setAnchorElInput(null);
							onChangeValue(path, e);
						}}
						handleClose={() => setAnchorElInput(null)}
					/>
				</MDBox>
			</TableCell>
			<IconButton onClick={() => deleteRow(path)}>
				<Icon>delete</Icon>
			</IconButton>
		</MDBox>
	);
};

export default RowMapping;
