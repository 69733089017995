import { useEffect, useMemo, useState } from "react";

import { t } from "i18next";

import Typology from "components/Custom/Typology";
import AdvancedInput from "components/Custom/AdvancedInput";

import { TYPE } from "../../constants";

const ReviewType = ({
	alternativeIndex,
	value,
	dictionary,
	setRightMenuPannel,
	language,
	languages,
	onChangePath,
	contact,
	location,
	link,
	survey,
	document,
	isEditMode,
	typologyType,
	channelType,
	locationRequest
}) => {
	const [lastUpdate, setLastUpdate] = useState("");
	const [triggerMemo, setTriggerMemo] = useState(false);

	useEffect(() => {
		if (lastUpdate !== value) {
			setTriggerMemo(prev => !prev);
		}
	}, [value]);

	return (
		/**
		 * useMemo permet de rendre le composant uniquement lorsque triggerMemo change
		 * sinon, le composant serait re-rendu à chaque frappe de clavier
		 * ce qui causerait des problèmes de performance et des bugs visuels
		 */
		useMemo(() => {
			if (channelType === TYPE.review)
				return (
					<AdvancedInput
						rounded
						value={value}
						dictionary={dictionary}
						label={t("COMPONENT.ANSWERTYPE.typeAnswer")}
						onChange={e => {
							setLastUpdate(prev => e);
							onChangePath("text", e);
						}}
						style={{
							borderRadius: "0.375rem 0 0 0.375rem"
						}}
						onFocus={() => {
							setRightMenuPannel({
								open: true,
								alternative: alternativeIndex,
								language: language.language,
								languages
							});
						}}
					/>
				);
			return (
				<Typology
					dictionary={dictionary}
					value={value}
					onChangePath={(type, e) => {
						onChangePath(type, e);
						setLastUpdate(prev => e);
					}}
					contact={contact}
					location={location}
					link={link}
					survey={survey}
					document={document}
					isEditMode={isEditMode}
					typologyType={typologyType}
					locationRequest={locationRequest}
					language={language.language}
				/>
			);
		}, [triggerMemo, typologyType])
	);
};

export default ReviewType;
