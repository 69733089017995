/* eslint-disable no-cond-assign */
/* eslint-disable prefer-destructuring */
import lod_ from "lodash";
import { FileIcon, defaultStyles } from "react-file-icon";
import MDBox from "components/Basics/MDBox";
import { uploadToSignedUrl, getSignedUrl, deleteFileFromPath } from "helpers/s3";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import MDTypography from "components/Basics/MDTypography";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	IconButton,
	Menu,
	MenuItem,
	Tooltip
} from "@mui/material";
import AdvancedInput from "components/Custom/AdvancedInput";
import { t } from "i18next";
import { grey } from "@mui/material/colors";
import FaibrikVariable from "components/Custom/AdvancedInput/FaibrikVariable";
import MDButton from "components/Basics/MDButton";
import { v4 } from "uuid";
import EditorBlock from "components/Custom/BlockNote/BlockNote";

/**
 * For a given file name, return the file extension
 * @param {String} fileName - The file name
 * @returns String
 */
const getFileExtension = fileName => {
	if (fileName) {
		const splited = fileName.split(".");
		if (splited.length > 1) {
			const ext = splited[splited.length - 1];
			return `.${ext.toUpperCase()}`;
		} else {
			return "";
		}
	} else {
		return "";
	}
};

/**
 * Ticket type component
 * @param {*} param0
 * @returns JSX
 */

const TicketType = ({
	code,
	value,
	dictionary,
	alternativeIndex,
	languageAlternativeContent,
	attachments,
	setAttachments,
	onChangePath,
	language,
	setOpenEditor,
	addNewAttachment,
	onHtmlEditorSave,
	editMode,
	answerItemCode
}) => {
	const fileInputRef = useRef();
	const [fileInputValue, setFileInputValue] = useState("");
	const [attachmentMenuAnchorEl, setAttachmentMenuAnchorEl] = useState(null);
	const [openAttachmentLinkDialog, setOpenAttachmentLinkDialog] = useState(false);

	const [lastUpdate, setLastUpdate] = useState("");
	const [triggerMemo, setTriggerMemo] = useState(false); // Var to trigger the memo

	const [lastEditorUpdate, setLastEditorUpdate] = useState("");
	const [triggerEditor, setTriggerEditor] = useState(false); // Var to trigger the editor

	/**
	 * Upload a file to S3
	 * - Get a signed URL
	 * - Upload the file
	 * @param {*} answerItemFile - The file to upload
	 * @param {*} answerItemID - The answer item ID
	 * @param {*} type - The type of the file
	 * @param {*} langue - The language
	 * @returns Object? - The uploaded file object
	 */
	async function uploadS3File(answerItemFile, answerItemID, type, langue) {
		try {
			// 1- Get the signed URL
			const { success, error, result } = await getSignedUrl(
				answerItemFile,
				{
					type,
					answerItemID,
					langue
				},
				"answerItem"
			);

			if (!success) {
				return null;
			}

			let signedUrl = result.signedUrl;
			let fileObject = result.fileObject;

			// 2- Upload the file
			await uploadToSignedUrl(signedUrl, answerItemFile);
			return fileObject;
		} catch (error) {
			// error
			return null;
		}
	}

	/**
	 * Read a file from the file input
	 * @param {*} e
	 */
	const readFileHandler = async e => {
		const file = e.target.files[0];
		let fileSize = -1;
		if (file) {
			fileSize = file.size;
		}
		if (fileSize > 0) {
			let fileObject = await uploadS3File(file, code, "attachment", language.language);
			if (fileObject && !lod_.isEmpty(fileObject)) {
				let newAttachmentsList = lod_.cloneDeep(attachments);
				newAttachmentsList.push(fileObject);
				setAttachments(newAttachmentsList);
				onChangePath("attachments", newAttachmentsList);
				addNewAttachment(fileObject, alternativeIndex);
			}
		}
		setFileInputValue("");
	};

	/**
	 * Function to decode a string containing handlebar-like expressions to JSX
	 * @param {string} content - The string content to decode
	 * @returns {string} - Decoded content as a static HTML string
	 */
	const decode = content => {
		// Check if the content is not a key to a dictionary
		let path = content.replaceAll(".", ".items.");
		let item = lod_.get(dictionary, path);
		if (item) {
			// If item is found, convert it to a handlebar-like expression
			content = `{{{ ${content} }}}`;
		}

		// Regular expression to find handlebar-like expressions
		const regex = /\{\{\{\s*(.+?)\s*\}\}\}/g;
		const parts = []; // Array to hold parts of the content
		let lastIndex = 0; // To keep track of the last matched index

		let match;

		// Loop through all matches in the content
		while ((match = regex.exec(content)) !== null) {
			// Push the text before the match
			if (match.index > lastIndex) {
				parts.push(content.substring(lastIndex, match.index));
			}

			// Extract and trim the variable inside the handlebar expression
			const variable = match[1].trim();

			// Push the custom variable component
			parts.push(<FaibrikVariable key={match.index} path={variable} dictionary={dictionary} />);

			lastIndex = regex.lastIndex; // Update last index
		}

		// Push the remaining text after the last match
		if (lastIndex < content.length) {
			parts.push(content.substring(lastIndex));
		}

		let html = ReactDOMServer.renderToStaticMarkup(parts);

		html = html.replaceAll("</span> ", "</span>&nbsp;");

		// Function to decode HTML entities
		const decodeHtmlEntities = str => {
			const textarea = document.createElement("textarea");
			textarea.innerHTML = str;
			return textarea.value;
		};

		return decodeHtmlEntities(html);
	};

	/**
	 * Get input subject component depending on the useLastMessageSubject value
	 * @returns {JSX} - The input component
	 */
	const getInputComponent = () => {
		if (languageAlternativeContent?.useLastMessageSubject === true) {
			/* If we're using the last message's subject */
			return (
				<MDTypography variant="caption" color="textSecondary">
					{t("COMPONENT.ANSWERTYPE.TICKET.useLastMessageSubject")}
				</MDTypography>
			);
		} else {
			/* Custom subject */
			/**
			 * useMemo allow to render the component only when the triggerMemo changes
			 * otherwise, the component will be re-rendered at each keystroke
			 * and we have some bad glitches
			 */
			return (
				<AdvancedInput
					value={languageAlternativeContent?.subject || ""}
					dictionary={dictionary}
					label={t("COMPONENT.ANSWERTYPE.TICKET.subject")}
					onChange={e => {
						setLastUpdate(prev => e);
						onChangePath("subject", e);
					}}
					style={{
						borderRadius: "0.375rem 0 0 0.375rem"
					}}
				/>
			);
		}
	};

	/**
	 * Trigger the memo when the languageAlternativeContent changes
	 * => Component will re-render and the value will be updated
	 */
	useEffect(() => {
		if (lastUpdate !== languageAlternativeContent?.subject) {
			setTriggerMemo(prev => !prev);
		}
	}, [languageAlternativeContent?.subject]);

	useEffect(() => {
		if (value !== lastEditorUpdate) {
			setTriggerEditor(prev => !prev);
		}
	}, [value]);

	return (
		<MDBox display="flex" flexDirection="column" sx={{ flex: 6, mr: 1 }}>
			<MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="stretch">
				{useMemo(() => {
					return (
						<MDBox
							flex="1"
							className={
								languageAlternativeContent?.useLastMessageSubject === true ? "boxInputStyle" : ""
							}
							borderRadius="md"
							style={{
								borderRadius: "0.375rem 0 0 0.375rem"
							}}
						>
							{getInputComponent()}
						</MDBox>
					);
				}, [triggerMemo, languageAlternativeContent?.useLastMessageSubject])}
				<Tooltip
					title={
						languageAlternativeContent?.useLastMessageSubject === true
							? t("COMPONENT.ANSWERTYPE.TICKET.addCustomSubject")
							: t("COMPONENT.ANSWERTYPE.TICKET.useLastSubject")
					}
					placement="top"
				>
					<MDBox
						className="endButtonboxInputStyle"
						display="flex"
						justifyContent="center"
						alignItems="center"
						bgColor={languageAlternativeContent?.useLastMessageSubject === true ? "dark" : "light"}
						onClick={() => {
							let newSubjectValue = !languageAlternativeContent?.useLastMessageSubject;
							onChangePath("useLastMessageSubject", newSubjectValue);
							// If we are using the last message subject, we clear the subject
							if (newSubjectValue) {
								onChangePath("subject", "");
							}
						}}
					>
						<Icon
							style={{
								color:
									languageAlternativeContent?.useLastMessageSubject === true ? grey[50] : "inherit"
							}}
							fontSize="small"
						>
							sms
						</Icon>
					</MDBox>
				</Tooltip>
			</MDBox>
			{/* Editor */}
			<EditorBlock
				language={language}
				dictionary={dictionary}
				onSetHtml={(html, json) => {
					setLastEditorUpdate(prev => html);
					onHtmlEditorSave(html, false, language.language);
				}}
				value={value}
				trigger={triggerEditor}
			/>
			{/* Attachments */}
			<MDBox sx={{ mt: 1 }} display="flex" flexDirection="row" alignItems="center">
				<Tooltip placement="top" title={t("COMPONENT.ANSWERTYPE.TICKET.addAttachment")}>
					<IconButton
						onClick={e => {
							setAttachmentMenuAnchorEl(e.currentTarget);
						}}
					>
						<Icon>attach_file</Icon>
					</IconButton>
				</Tooltip>
				<Menu
					anchorEl={attachmentMenuAnchorEl}
					open={Boolean(attachmentMenuAnchorEl)}
					onClose={() => setAttachmentMenuAnchorEl(null)}
				>
					<MenuItem
						onClick={() => {
							fileInputRef.current.click();
							setAttachmentMenuAnchorEl(null);
						}}
					>
						<Icon>cloud_upload</Icon>&nbsp;{t("COMPONENT.ANSWERTYPE.TICKET.fromComputer")}
					</MenuItem>
					<MenuItem
						onClick={() => {
							setOpenAttachmentLinkDialog(true);
							setAttachmentMenuAnchorEl(null);
						}}
					>
						<Icon>link</Icon>&nbsp;{t("COMPONENT.ANSWERTYPE.TICKET.fromURL")}
					</MenuItem>
				</Menu>
				<input
					type="file"
					style={{ display: "none" }}
					onChange={readFileHandler}
					value={fileInputValue}
					ref={fileInputRef}
				/>
				<MDBox
					display="flex"
					flexDirection="row"
					style={{
						overflowX: "auto"
					}}
				>
					{!attachments.length && (
						<MDTypography variant="caption" color="textSecondary" sx={{ fontStyle: "italic" }}>
							{t("COMPONENT.ANSWERTYPE.TICKET.noAttachment")}
						</MDTypography>
					)}
					{attachments.map((attachment, index) => {
						return (
							<MDBox
								key={index}
								sx={{ ml: 1 }}
								bgColor="light"
								style={{
									padding: "5px 7px"
								}}
								borderRadius="md"
								display="flex"
								flexDirection="row"
								alignItems="center"
							>
								{attachment.type === "variable" ? (
									<Tooltip placement="top" title={attachment.url}>
										<Icon>link</Icon>
									</Tooltip>
								) : (
									<MDBox
										style={{
											width: "18px"
										}}
										display="flex"
										alignItems="center"
										shadow="md"
									>
										<FileIcon
											extension={getFileExtension(attachment.name).replace(".", "")}
											{...defaultStyles[
												getFileExtension(attachment.name).replace(".", "").toLowerCase()
											]}
										/>
									</MDBox>
								)}

								<MDBox
									sx={{ ml: 1 }}
									dangerouslySetInnerHTML={{ __html: decode(attachment.name) }}
									style={{
										fontSize: "0.8rem",
										fontWeight: "bold"
									}}
								></MDBox>

								<IconButton
									size="small"
									onClick={async () => {
										if (attachment.type !== "variable") {
											await deleteFileFromPath({ key: attachment.key });
										}

										let newAttachmentsList = lod_
											.cloneDeep(attachments)
											.filter((_, i) => i !== index);
										setAttachments(newAttachmentsList);
										onChangePath("attachments", newAttachmentsList);
									}}
								>
									<Icon>delete</Icon>
								</IconButton>
							</MDBox>
						);
					})}
				</MDBox>
			</MDBox>
			{/* Link attachments */}
			<DialogLink
				openAttachmentLinkDialog={openAttachmentLinkDialog}
				setOpenAttachmentLinkDialog={setOpenAttachmentLinkDialog}
				dictionary={dictionary}
				onSave={link => {
					let newAttachmentsList = lod_.cloneDeep(attachments);
					newAttachmentsList.push(link);
					setAttachments(newAttachmentsList);
					onChangePath("attachments", newAttachmentsList);
				}}
			/>
		</MDBox>
	);
};

/**
 * Open a dialog to add a link (name and url)
 * Can put variables in the name and url
 * @param {*} param0
 * @returns
 */
const DialogLink = ({
	openAttachmentLinkDialog,
	setOpenAttachmentLinkDialog,
	dictionary,
	onSave
}) => {
	const [name, setName] = useState("");
	const [value, setValue] = useState("");

	return (
		<Dialog
			open={openAttachmentLinkDialog}
			onClose={() => setOpenAttachmentLinkDialog(false)}
			maxWidth="md"
			fullWidth
		>
			<DialogTitle>{t("COMPONENT.ANSWERTYPE.TICKET.addURL")}</DialogTitle>
			<DialogContent>
				<AdvancedInput
					rounded
					dictionary={dictionary}
					label={t("SETTINGS.PAGES.name")}
					onChange={e => {
						setName(e);
					}}
					style={{
						borderRadius: "0.375rem 0 0 0.375rem"
					}}
				/>
				<MDBox sx={{ mt: 1 }}>
					<AdvancedInput
						rounded
						dictionary={dictionary}
						label={t("COMPONENT.ANSWERTYPE.TICKET.URL")}
						onChange={e => {
							setValue(prev => e);
						}}
						style={{
							borderRadius: "0.375rem 0 0 0.375rem"
						}}
					/>
				</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton
					variant="outlined"
					color="info"
					onClick={() => {
						setOpenAttachmentLinkDialog(false);
					}}
				>
					{t("SETTINGS.cancel")}
				</MDButton>
				<MDButton
					disabled={!value}
					variant="contained"
					color="info"
					onClick={() => {
						setOpenAttachmentLinkDialog(false);
						onSave({
							name,
							url: value,
							path: v4(),
							type: "variable"
						});
					}}
				>
					{t("SETTINGS.add")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
};

export default TicketType;
