import React, { useMemo, useCallback, useEffect } from "react";
import { t } from "i18next";
import { BubbleMenu, EditorContent, FloatingMenu, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Mention from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";
import useMapDictionary from "../../../hooks/useMapDictionary";
import suggestion from "./Suggestion";
import "./MentionList.css";

const convertTextToHtml = text => {
	if (!text.includes("\n")) {
		return `<p>${text.trim()}</p>`;
	}
	return text
		.split("\n")
		.filter(line => line.trim() !== "")
		.map(line => `<p>${line.trim()}</p>`)
		.join("");
};

const replaceMentionsWithHandlebars = (text, existingKeys) => {
	return existingKeys.reduce(
		(modifiedText, keyItem) =>
			modifiedText.replaceAll(
				`{{{ ${keyItem.variable} }}}`,
				`<span data-type="mention" class="mention" data-id="${keyItem.value}"> ${keyItem.value}</span>`
			),
		text
	);
};

const replaceHandlebarsMentions = (text, existingKeys) => {
	return existingKeys.reduce(
		(modifiedText, keyItem) =>
			modifiedText.replaceAll(`${keyItem.value}`, `{{{ ${keyItem.variable} }}}`),
		text
	);
};

const ConversationInput = ({ dictionary, onchangeInputChange, value }) => {
	const { mappedDictionary, existingKeys } = useMapDictionary(dictionary);

	const extensions = useMemo(
		() => [
			StarterKit,
			Placeholder.configure({
				placeholder: t("COMPONENT.ANSWERTYPE.typeAnswer")
			}),
			Mention.configure({
				HTMLAttributes: { class: "mention" },
				deleteTriggerWithBackspace: true,
				suggestion: {
					...suggestion,
					items: () => mappedDictionary ?? []
				},
				renderText: ({ node }) => `${node.attrs.label ?? node.attrs.id}`,
				renderLabel: ({ node }) => `${node.attrs.label ?? node.attrs.id}`
			})
		],
		[mappedDictionary]
	);

	const preparedContent = useMemo(() => {
		if (!value) return " ";
		const newHtml = convertTextToHtml(value);
		return replaceMentionsWithHandlebars(newHtml, existingKeys || []);
	}, [value, existingKeys]);

	const handleUpdate = useCallback(
		({ editor }) => {
			const newText = editor.getText();
			const processedText = replaceHandlebarsMentions(newText, existingKeys || []);
			onchangeInputChange(processedText);
		},
		[existingKeys, onchangeInputChange]
	);

	const editor = useEditor(
		{
			extensions,
			content: preparedContent,
			onUpdate: handleUpdate
		},
		[extensions]
	);

	useEffect(() => {
		if (editor && preparedContent) {
			editor.commands.setContent(preparedContent);
		}
	}, [editor, preparedContent]);

	if (!editor) {
		return null;
	}

	return (
		<>
			<EditorContent editor={editor} />
		</>
	);
};

export default ConversationInput;
