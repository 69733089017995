/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
import { getLabel, getDictionaryPath } from "helpers/dictionary";
import { t } from "i18next";
import lod_ from "lodash";

/**
 * Get a flat array of all paths from an object
 * @param {*} obj
 * @param {*} currentPath
 * @returns
 */
function getAllPaths(obj, currentPath = "") {
	return lod_.flatMap(lod_.keys(obj), key => {
		const newPath = currentPath ? `${currentPath}.${key}` : key;
		if (lod_.isObject(obj[key]) && !lod_.isArray(obj[key])) {
			return getAllPaths(obj[key], newPath);
		}
		return newPath;
	});
}

/**
 * Validation, works with <LittleForm /> component
 * @param {*} values
 * @param {*} dictionary
 * @returns {valid: boolean, errors: object} - valid: true if all required fields are filled, errors: object with errors
 */
export const formValidation = (values, dictionary) => {
	let valid = true;
	let errors = {};

	const flatValues = getAllPaths(values);

	for (let path of flatValues) {
		const item = lod_.get(dictionary, getDictionaryPath(path));
		/**
		 * Check only for displayable items
		 */
		if (item?.isRequired && item.display) {
			const value = lod_.get(values, path);

			switch (item.type) {
				case "cron":
				case "number":
					if (lod_.isNil(value) || Number.isNaN(value)) {
						errors[path] = {
							label: getLabel(item.label),
							error: t("FORMS.ERRORS.isRequired")
						};
					}
					break;
				case "boolean": {
					if (lod_.isNil(value)) {
						errors[path] = {
							label: getLabel(item.label),
							error: t("FORMS.ERRORS.isRequired")
						};
					}
					break;
				}
				default: {
					if (lod_.isNil(value) || lod_.isEmpty(value)) {
						errors[path] = {
							label: getLabel(item.label),
							error: t("FORMS.ERRORS.isRequired")
						};
					}
					break;
				}
			}
		}
	}

	if (!lod_.isEmpty(errors)) {
		valid = false;
	}

	return {
		valid,
		errors
	};
};
