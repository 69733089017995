import "./mention.css";
import { createReactInlineContentSpec } from "@blocknote/react";

// The Mention inline content.
const Mention = createReactInlineContentSpec(
	{
		type: "mention",
		propSchema: {
			user: {
				default: "Unknown"
			}
		},
		content: "none"
	},
	{
		render: props => <span className="mention-tag">{props.inlineContent.props.user}</span>
	}
);

export default Mention;
