import React, { useState } from "react";
import { t } from "i18next";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { answerTypes, ANSWERS_CONVERSATION_FORMAT } from "../../../constants";

const SelectMenuTypology = ({ onSelectTedMenuTypology, typologySelected, isEdit = false }) => {
	const handleChange = event => {
		const selectedType = event.target.value;
		onSelectTedMenuTypology(selectedType);
		if (selectedType === ANSWERS_CONVERSATION_FORMAT.locationRequest) {
			const predefinedMessage = t("ANSWERTYPE.requestLocationMessage");
			onSelectTedMenuTypology(selectedType, predefinedMessage);
		}
	};

	return (
		<FormControl sx={{ width: "25%" }}>
			<InputLabel id="custom-select-label">{t("LABELS.types")}</InputLabel>
			<Select
				labelId="custom-select-label"
				id="custom-select"
				value={typologySelected}
				label={t("LABELS.types")}
				onChange={handleChange}
				readOnly={isEdit}
				renderValue={selected => {
					const selectedAnswer = answerTypes.find(answer => answer.type === selected);
					if (!selectedAnswer) return null;
					return (
						<div style={{ display: "flex", alignItems: "center" }}>
							{selectedAnswer.icon}
							<span style={{ marginLeft: 8 }}>{t(selectedAnswer.label)}</span>
						</div>
					);
				}}
			>
				{answerTypes.map(answerType => (
					<MenuItem
						key={answerType.type}
						value={answerType.type}
						sx={{
							height: "48px",
							display: "flex",
							alignItems: "center"
						}}
					>
						{answerType.icon}
						<span style={{ marginLeft: 8 }}>{t(answerType.label)}</span>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SelectMenuTypology;
