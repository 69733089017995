import { ANSWERS_CONVERSATION_FORMAT } from "../constants/index";

const useEditorManager = onChangePath => {
	const handleEditorInput = (type, value) => {
		const exclusiveTypes = [
			ANSWERS_CONVERSATION_FORMAT.richtext,
			ANSWERS_CONVERSATION_FORMAT.location,
			ANSWERS_CONVERSATION_FORMAT.contact,
			ANSWERS_CONVERSATION_FORMAT.menuyesno,
			ANSWERS_CONVERSATION_FORMAT.link,
			ANSWERS_CONVERSATION_FORMAT.locationRequest
		];

		if (exclusiveTypes.includes(type)) {
			exclusiveTypes.forEach(t => {
				if (t !== type) {
					onChangePath(t, undefined);
				}
			});
		}

		onChangePath(type, value);
	};

	return handleEditorInput;
};

export default useEditorManager;
