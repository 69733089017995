import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Poll from "@mui/icons-material/Poll";

import WrapperPaperTag from "../WrapperPaperTag";

const SurveyMenu = ({ question, options, onClick, onClose, isEditMode }) => {
	return (
		<WrapperPaperTag onClick={onClick} onClose={onClose} isEditMode={isEditMode}>
			<Box
				sx={{
					mt: 2,
					display: "flex",
					flexDirection: "column",
					gap: 1,
					borderRadius: "4px",
					padding: "16px",
					width: "fit-content",
					maxWidth: "100%",
					margin: "8px"
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
					<Poll fontSize="small" />
					<Typography variant="body2" sx={{ fontWeight: "bold" }}>
						Sondage
					</Typography>
				</Box>
				<Typography variant="h6" sx={{ fontWeight: "medium" }}>
					{question}
				</Typography>
				{Array.isArray(options) ? (
					options.map((option, index) => (
						<Typography key={index} variant="body2" sx={{ color: "#666" }}>
							{`Option ${index + 1}: ${option}`}
						</Typography>
					))
				) : (
					<>
						{options?.option1 && (
							<Typography variant="body2" sx={{ color: "#666" }}>
								{`Option 1: ${options.option1}`}
							</Typography>
						)}
						{options?.option2 && (
							<Typography variant="body2" sx={{ color: "#666" }}>
								{`Option 2: ${options.option2}`}
							</Typography>
						)}
					</>
				)}
			</Box>
		</WrapperPaperTag>
	);
};

export default SurveyMenu;
