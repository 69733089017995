/* eslint-disable no-underscore-dangle */

import lod_ from "lodash";

import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";

import { Icon } from "@mui/material";

import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import EditorBlock from "components/Custom/BlockNote/BlockNote";

import FormAction from "redux-react/actions/formAction";
import { display } from "redux-react/reducers/snackBarReducer";
import ConfirmDialogButton from "components/Custom/Dialogs/ConfirmDialogButton";
import { setStuckAction, resetStuckAction } from "redux-react/reducers/applicationReducer";
import useMapDictionary from "hooks/useMapDictionary";

/**
 * Signature editor, allow to edit a signature
 * @param {*} param0
 * @returns {JSX.Element} - Signature editor component
 */
const SignatureEditor = ({ signature, dictionaries, getSignatures, onDelete }) => {
	const { existingKeys } = useMapDictionary(dictionaries);

	const dispatch = useDispatch();

	// Check if new signature is equal to DB signature
	// Used to disable or not the save button
	const [isEqual, setIsEqual] = useState(true);

	// Current value of the signature (edition)
	const [value, setValue] = useState(signature.content.text);

	/**
	 * Save the new signature to DB
	 * @param {string} html - The HTML content
	 * @param {string} id - The ID of document in DB
	 */
	const saveSignature = (html, id) => {
		let data = {
			values: {
				content: {
					text: html
				}
			},
			target: "answerItem",
			unique: [],
			actions: ["updateSignatureAction"]
		};

		dispatch(
			FormAction.updateItem(id, data, () => {
				// Reload signature at the end
				getSignatures(false);
			})
		);
	};

	/**
	 * Delete a signature in DB
	 * @param {string} id - The ID of the signature
	 */
	const deleteSignature = id => {
		const onSuccess = res => {
			dispatch(
				display({
					message: t("FORMS.deleteSuccess"),
					type: "success"
				})
			);
			// Reload the signatures
			onDelete(true);
			getSignatures();
		};
		dispatch(
			FormAction.deleteItem(
				id,
				"answerItem",
				{
					delete: true,
					actions: ["deleteSignatureAction"]
				},
				onSuccess
			)
		);
	};

	/**
	 * When user is typing, check if the signature is equal to the DB signature
	 * If not, set the stuck action to save the signature. It prevent user to leave the page without saving
	 */
	const debouncedUpdate = useCallback(
		lod_.debounce((value, signature, dispatch) => {
			const equal = lod_.isEqual(value, signature.content.text);
			setIsEqual(equal);

			if (!equal) {
				dispatch(
					setStuckAction({
						status: true,
						save: () => saveSignature(value, signature._id),
						cancel: () => {},
						next: () => {},
						openDialog: false
					})
				);
			} else {
				dispatch(resetStuckAction());
			}
		}, 300),
		[]
	);

	useEffect(() => {
		setValue(signature.content.text);
	}, [signature]);

	useEffect(() => {
		debouncedUpdate(value, signature, dispatch);
	}, [value, debouncedUpdate]);

	return (
		<MDBox
			flex="1"
			display="flex"
			flexDirection="column"
			sx={{
				overflow: "auto"
			}}
		>
			<MDBox mb={1} display="flex" flexDirection="row" justifyContent="end" alignItems="center">
				<ConfirmDialogButton
					onConfirm={e => {
						e.stopPropagation();
						deleteSignature(signature._id);
					}}
					onCancel={e => {
						e.stopPropagation();
					}}
					component={
						<MDButton size="small" variant="contained" color="error">
							<Icon>delete</Icon>&nbsp;Supprimer
						</MDButton>
					}
					title={`${t("DIALOG.DELETE.delete")} "${signature.name}"`}
					content={`${t("DIALOG.DELETE.confirmationDelete")} "${signature.name}" ?`}
				/>
				<MDButton
					size="small"
					variant="contained"
					color="info"
					onClick={() => {
						saveSignature(value, signature._id);
						setIsEqual(true);
						dispatch(resetStuckAction());
					}}
					sx={{ ml: 1 }}
					disabled={isEqual}
				>
					<Icon>save</Icon>&nbsp;Enregistrer les modifications
				</MDButton>
			</MDBox>
			<MDBox
				flex="1"
				style={{
					overflow: "auto",
					position: "relative"
				}}
			>
				<EditorBlock
					dictionary={dictionaries}
					onSetHtml={html => {
						if (html) {
							// Replace span by handlebars variables
							existingKeys.forEach(keyItem => {
								html = html.replaceAll(
									`<span class="mention-tag" data-inline-content-type="mention" data-user="${keyItem.value}">${keyItem.value}</span>`,
									`{{{ ${keyItem.variable} }}}`
								);
							});
						}
						setValue(html);
					}}
					value={signature.content.text}
					trigger={signature}
				/>
			</MDBox>
		</MDBox>
	);
};

export default SignatureEditor;
