import "./style.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormAction from "redux-react/actions/formAction";
import { display } from "redux-react/reducers/snackBarReducer";
import { getStuckAction, openStuckDialog } from "redux-react/reducers/applicationReducer";

import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import { Icon } from "@mui/material";
import { t } from "i18next";

import { queryDictionaries } from "helpers/dictionary";
import SignatureInformations from "./components/SignatureInformations";
import SignatureItemList from "./components/SignatureItemList";
import SignatureEditor from "./components/SignatureEditor";

/**
 * Signature component
 */
const SignatureComponent = ({ channel, common, onChange }) => {
	const stuck = useSelector(getStuckAction);

	const [dictionaries, setDictionaries] = useState({});

	const dispatch = useDispatch();

	const setupDictionaries = async () => {
		const dictionariesList = await queryDictionaries(dispatch, ["contact", "context", "profile"]);
		setDictionaries(dictionariesList);
	};

	// Open the complete signature dialog
	const [createSignatureOpen, setCreateSignatureOpen] = useState({
		open: false
	});

	const [selectedSignature, setSelectedSignature] = useState(null);

	// List of signatures
	const [signatures, setSignatures] = useState([]);

	/* Get all signatures for this channel */
	const getSignatures = async (setSignature = true) => {
		dispatch(
			FormAction.getItemsFromCollection(
				"answerItem",
				{
					query: {
						documentType: "signature",
						channel: channel.type
					}
				},
				res => {
					const resSignatures = res?.items || [];
					setSignatures(resSignatures);
					if (setSignature) {
						const signature = resSignatures.find(s => s.code === common.signatureCode);

						if (signature) {
							setSelectedSignature(signature);
						}
					}
				}
			)
		);
	};

	/* Add a new signature */
	const addSignature = async data => {
		/**
		 * Signature (answer item) structure
		 */
		const signature = {
			documentType: "signature",
			code: data.code,
			name: data.name,
			description: data.description ?? "",
			// Channel type
			channel: channel.type,
			// Channel code
			channelCode: common.code,
			content: {
				text: ""
			},
			type: "signature",
			language: "ALL"
		};

		dispatch(
			FormAction.addItemEmpty(
				{
					values: signature,
					target: "answerItem",
					unique: { code: signature.code },
					actions: ["insertSignatureAction"]
				},
				() => {
					dispatch(
						display({
							message: t("CHANNEL.SIGNATURE.successfullyAdded"),
							type: "success"
						})
					);
					// Close the complete signature dialog
					setCreateSignatureOpen({ open: false });
					// Reload the signatures
					getSignatures();
				}
			)
		);
	};

	const switchItem = signature => {
		onChange("signatureCode", signature.code);
		const getSignature = signatures.find(s => s.code === signature.code);
		setSelectedSignature(getSignature);
	};

	/* On load, get the signatures */
	useEffect(() => {
		getSignatures();
		setupDictionaries();
	}, []);

	return (
		<MDBox sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
			<MDBox flex={2} display="flex" flexDirection="column">
				<MDBox
					flex="1"
					style={{
						overflow: "auto"
					}}
				>
					{signatures.map((signature, index) => (
						<SignatureItemList
							key={index}
							signature={signature}
							selected={selectedSignature?.code === signature.code}
							onClick={() => {
								if (stuck.status === true) {
									dispatch(
										openStuckDialog(() => {
											switchItem(signature);
										})
									);
								} else {
									switchItem(signature);
								}
							}}
						/>
					))}
				</MDBox>
				<MDBox pt={1} display="flex" justifyContent="space-between">
					{/* Unselect signature */}
					<MDButton
						size="small"
						variant="contained"
						color="light"
						onClick={() => {
							onChange("signatureCode", null);
							setSelectedSignature(null);
						}}
					>
						<Icon>close</Icon>&nbsp;Aucune signature
					</MDButton>
					{/* Add new signature */}
					<MDButton
						size="small"
						variant="contained"
						color="info"
						onClick={() => {
							setCreateSignatureOpen({
								open: true
							});
						}}
					>
						<Icon>add</Icon>&nbsp;Ajouter
					</MDButton>
				</MDBox>
			</MDBox>
			<MDBox
				flex={4}
				ml={2}
				display="flex"
				flexDirection="column"
				sx={{
					overflow: "auto"
				}}
			>
				{selectedSignature && (
					<SignatureEditor
						signature={selectedSignature}
						dictionaries={dictionaries}
						getSignatures={getSignatures}
						onDelete={res => {
							setSelectedSignature(null);
						}}
					/>
				)}
			</MDBox>
			{/* Signature creation */}
			<SignatureInformations
				open={createSignatureOpen.open}
				onClose={() => setCreateSignatureOpen({ open: false })}
				onSave={data => addSignature(data)}
			/>
		</MDBox>
	);
};

export default SignatureComponent;
