import React, { useState, useEffect, useRef, useMemo } from "react";

import Box from "@mui/material/Box";
import useEditorManager from "../../../hooks/useEditorManager";
import { ANSWERS_CONVERSATION_FORMAT } from "../../../constants/index";
import DialogGoogleMap from "../GoogleMap/DiaglogGoogleMap/DialogGoogleMap";
import ConversationInput from "../ConversationInput/ConversationInput";
import TypologyDocument from "./File/TypologyDocument";
import DialogContact from "../DialogContact";
import DialogSurvey from "../DialogSurvey";
import DialogLink from "../DialogLink";
import SurveyMenu from "./SurveyMenu";
import TypologyLink from "./Link";
import Location from "./Location";
import Contact from "./Contact";
import MenuTypology from "./MenuTypology";

const Typology = ({
	dictionary,
	onchangeInputChange,
	value,
	onChangePath,
	isEditMode,
	contact,
	location,
	link,
	survey,
	document,
	typologyType,
	locationRequest,
	language
}) => {
	const [typologiesValue, setTypologiesValue] = useState({});
	const [openDialogGoogleMap, setOpenDialogGoogleMap] = useState(false);
	const [openDialogContact, setopenDialogContact] = useState(false);
	const [openDialogLink, setOpenDialogLink] = useState(false);
	const [openDialogSurvey, setOpenDialogSurvey] = useState(false);
	const fileInputRef = useRef(null);

	const handleEditorInput = useEditorManager(onChangePath);

	const messageText =
		language === "EN" ? "Please share your location" : "Veuillez partagez votre position";

	const handleTypeChange = (type, value) => {
		setTypologiesValue(value);
		if (type === ANSWERS_CONVERSATION_FORMAT.locationRequest) {
			onChangePath(type, { locationRequest: value });
		} else {
			handleEditorInput(type, value?.[type ?? ""]);
		}
	};

	const memoizeValue = useMemo(() => typologyType, [typologyType]);
	useEffect(() => {
		setTypologiesValue({});
	}, [memoizeValue]);

	useEffect(() => {
		if (location) setTypologiesValue({ location });
		if (contact) setTypologiesValue({ contact });
		if (link) setTypologiesValue({ link });
		if (survey) setTypologiesValue({ survey });
		if (document) setTypologiesValue({ document });
		if (locationRequest) setTypologiesValue(locationRequest);
	}, []);

	useEffect(() => {
		if (typologyType === ANSWERS_CONVERSATION_FORMAT.locationRequest) {
			const value = {
				locationRequest: {
					text: messageText
				}
			};
			handleTypeChange(
				ANSWERS_CONVERSATION_FORMAT.locationRequest,
				value[ANSWERS_CONVERSATION_FORMAT.locationRequest]
			);

			setTypologiesValue(value);
		}
	}, [typologyType]);

	const handleReselectFile = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const isExlusiveTypology = [
		ANSWERS_CONVERSATION_FORMAT.richtext,
		ANSWERS_CONVERSATION_FORMAT.document
	]?.includes(typologyType);

	const isLocation = Boolean(typologiesValue?.location);
	const isContact = Boolean(typologiesValue?.contact);
	const isDocument = Boolean(typologiesValue?.document);
	const isLink = Boolean(typologiesValue?.link);
	const isSurvey = Boolean(typologiesValue?.survey);
	const isLocationRequest = Boolean(typologiesValue?.locationRequest);

	const hasTypology =
		isLocation || isContact || isLink || isSurvey || !isExlusiveTypology || isLocationRequest;
	return (
		<Box sx={{ display: "flex", alignItems: "flex-start", gap: 1, flexDirection: "column" }}>
			<Box
				component="div"
				sx={
					isExlusiveTypology
						? {
								border: "1px solid #ccc",
								p: 2,
								minHeight: "150px",
								borderRadius: "4px",
								mt: 2,
								outline: "none",
								flex: 1,
								width: "100%"
							}
						: {}
				}
			>
				{!hasTypology && (
					<ConversationInput
						dictionary={dictionary}
						value={value}
						onchangeInputChange={e => onChangePath(ANSWERS_CONVERSATION_FORMAT.richtext, e)}
					/>
				)}

				{isLocation && (
					<Location
						onClick={() => setOpenDialogGoogleMap(true)}
						position={typologiesValue.location}
						onClose={() => {
							setTypologiesValue({});
							onChangePath(ANSWERS_CONVERSATION_FORMAT.location);
						}}
						isEditMode={isEditMode}
					/>
				)}

				{isContact && (
					<Contact
						onClick={() => setopenDialogContact(true)}
						firstName={typologiesValue?.contact?.firstName}
						lastName={typologiesValue?.contact?.lastName}
						onClose={() => {
							setTypologiesValue({});
							onChangePath(ANSWERS_CONVERSATION_FORMAT.contact);
						}}
						isEditMode={isEditMode}
					/>
				)}

				{isLink && (
					<TypologyLink
						onClick={() => setOpenDialogLink(true)}
						title={typologiesValue.link.title}
						description={typologiesValue.link.description}
						link={typologiesValue.link.link}
						onClose={() => {
							setTypologiesValue({});
							onChangePath(ANSWERS_CONVERSATION_FORMAT.link);
						}}
						isEditMode={isEditMode}
					/>
				)}

				{isSurvey && (
					<SurveyMenu
						onClick={() => setOpenDialogSurvey(true)}
						question={typologiesValue.survey.question}
						options={typologiesValue.survey.options || []}
						onClose={() => {
							setTypologiesValue({});
							onChangePath(ANSWERS_CONVERSATION_FORMAT.menuyesno);
						}}
						isEditMode={isEditMode}
					/>
				)}

				{isDocument && (
					<TypologyDocument
						document={typologiesValue.document}
						onClose={() => {
							setTypologiesValue({});
							onChangePath(ANSWERS_CONVERSATION_FORMAT.document);
						}}
						isEditMode={isEditMode}
						onReselect={handleReselectFile}
					/>
				)}
			</Box>

			<Box>
				<MenuTypology
					onTypeChange={handleTypeChange}
					fileInputRef={fileInputRef}
					typologyType={typologyType}
				/>
			</Box>

			{openDialogContact && (
				<DialogContact
					contact={typologiesValue?.contact}
					onAddContact={value => {
						handleTypeChange(ANSWERS_CONVERSATION_FORMAT.contact, value);
						setTypologiesValue(value);
					}}
					onClose={() => setopenDialogContact(false)}
					isEditMode
				/>
			)}
			{openDialogGoogleMap && (
				<DialogGoogleMap
					position={typologiesValue.location}
					onAddLocation={value => {
						onChangePath(ANSWERS_CONVERSATION_FORMAT.location, value);
						handleTypeChange(ANSWERS_CONVERSATION_FORMAT.location, value);
					}}
					onClose={() => setOpenDialogGoogleMap(false)}
					isEditMode
				/>
			)}
			{openDialogLink && (
				<DialogLink
					link={typologiesValue.link}
					onAddLink={value => {
						handleTypeChange(ANSWERS_CONVERSATION_FORMAT.link, value);
						setTypologiesValue(value);
					}}
					onClose={() => setOpenDialogLink(false)}
					isEditMode
				/>
			)}
			{openDialogSurvey && (
				<DialogSurvey
					menu={typologiesValue.survey}
					onClose={() => setOpenDialogSurvey(false)}
					isEditMode
					onAddSurvey={value => {
						handleTypeChange(ANSWERS_CONVERSATION_FORMAT.menuyesno, value);
						setTypologiesValue(value);
					}}
				/>
			)}
		</Box>
	);
};

export default Typology;
