import React from "react";
import WrapperPaperTag from "../WrapperPaperTag";
import File from "./File";
import { deleteFileFromPath } from "../../../../helpers/s3";

const TypologyDocument = ({ document, onClick, onClose, isEditMode, onReselect }) => {
	const fileData = document?.payload?.payload?.attachments?.[0] || null;

	const handleRemove = async () => {
		if (fileData?.key) {
			await deleteFileFromPath({ key: fileData.key });
		}
		onClose?.(); // Call parent’s onClose after deletion
	};

	return (
		<WrapperPaperTag onClick={onClick} onClose={handleRemove} isEditMode={isEditMode}>
			<File filesData={document} onReselect={onReselect} />
		</WrapperPaperTag>
	);
};

export default TypologyDocument;
