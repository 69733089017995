import { Icon } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

/**
 * Signature item in the list
 * @param {*} param0
 * @returns {JSX.Element} - Signature item
 */
const SignatureItemList = ({ signature, selected, onClick }) => {
	return (
		<MDBox
			mb={1}
			bgColor={selected ? "info" : "light"}
			borderRadius="lg"
			p={1}
			onClick={onClick}
			display="flex"
			flexDirection="row"
			alignItems="center"
			justifyContent="space-between"
		>
			<MDBox>
				<MDTypography variant="h6" color={selected ? "white" : "black"}>
					{signature.name}
				</MDTypography>
				<MDTypography variant="subtitle2" color={selected ? "white" : "black"}>
					{signature.description}
				</MDTypography>
			</MDBox>
			{selected && (
				<MDBox>
					<Icon color="white">check</Icon>
				</MDBox>
			)}
		</MDBox>
	);
};

export default SignatureItemList;
