import React from "react";

import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";

const WrapperPaperTag = ({
	label,
	onClose,
	children,
	onClick,
	isEditMode = false,
	isPaddingNoTop
}) => {
	const handleClose = e => {
		e.stopPropagation();
		onClose?.();
	};

	return (
		<Box
			sx={{
				px: 4,
				paddingTop: isPaddingNoTop ? 0 : 4,
				paddingBottom: 4,
				cursor: "pointer",
				width: "fit-content",
				height: "fit-content"
			}}
			onClick={() => onClick?.()}
		>
			<Stack spacing={2}>
				<Paper
					elevation={1}
					sx={{
						position: "relative",
						display: "inline-flex",
						alignItems: "center",
						p: 1.5,
						pl: 2,
						pr: 2,
						borderRadius: 1,
						border: "1px solid  #d2d6da",
						width: "fit-content",
						height: "fit-content"
					}}
				>
					{!isEditMode && (
						<IconButton
							size="small"
							onClick={handleClose}
							sx={{
								position: "absolute",
								left: -8,
								top: -8,
								bgcolor: "#ffff",
								border: "1px solid  #d2d6da",
								"&:hover": { bgcolor: "#F5F5F5" },
								padding: 0.5
							}}
						>
							<CloseIcon fontSize="small" />
						</IconButton>
					)}
					{children}
				</Paper>
			</Stack>
		</Box>
	);
};

export default WrapperPaperTag;
