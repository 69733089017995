import * as React from "react";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

import GoogleMap from "../../../../assets/images/charts/GoogleMap";
import WrapperPaperTag from "../WrapperPaperTag";

const Location = ({ position, onClick, onClose, isEditMode }) => {
	return (
		<WrapperPaperTag onClick={onClick} onClose={onClose} isEditMode={isEditMode} width={350}>
			<Stack alignItems="center" direction="row" gap={2}>
				<GoogleMap />
				<Tooltip title={position?.address || position?.name}>
					<Typography
						sx={{ width: 200, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
						variant="body1"
					>
						{position?.address || position?.name}
					</Typography>
				</Tooltip>
			</Stack>
		</WrapperPaperTag>
	);
};

export default Location;
