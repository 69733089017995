import { useState, useEffect } from "react";

import { t } from "i18next";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import MDButton from "components/Basics/MDButton";
import LinkForm from "./LinkForm";

const DialogLink = ({ onClose, onAddLink, link, isEditMode }) => {
	const [formData, setFormData] = useState({
		link: "",
		description: "",
		title: "",
		footer: "",
		buttonText: ""
	});
	const [isValidForm, setIsValidForm] = useState(false);

	useEffect(() => {
		if (link) setFormData(link);
	}, [link]);

	const handleChange = e => {
		const { name, value } = e.target;
		setFormData(prevState => ({
			...prevState,
			[name]: value
		}));
	};

	return (
		<Dialog open>
			<DialogTitle>
				{t("FORMS.linkTitle")}
				<IconButton
					edge="end"
					color="inherit"
					onClick={onClose}
					aria-label="close"
					style={{ float: "right" }}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<LinkForm
					formData={formData}
					onChangeForm={handleChange}
					onUpdateStatusForm={val => setIsValidForm(val)}
				/>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={onClose}>
					{t("SETTINGS.cancel")}
				</MDButton>
				<MDButton
					variant="contained"
					color="info"
					onClick={() => {
						onAddLink({ link: formData });
						onClose?.();
					}}
					disabled={!isValidForm}
				>
					{t(isEditMode ? "SETTINGS.edit" : "SETTINGS.add")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
};

export default DialogLink;
