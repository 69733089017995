import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/Basics/MDButton";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SurveyForm from "./SurveyForm";

const DialogSurvey = ({ onClose, onAddSurvey, menu, isEditMode }) => {
	const { t } = useTranslation();
	const [formData, setFormData] = useState({
		question: "",
		option1: "",
		option2: "",
		option3: ""
	});
	const [isValid, setIsValid] = useState(false);

	useEffect(() => {
		if (menu) {
			setFormData({
				question: menu.question || "",
				option1: menu.option1 || "",
				option2: menu.option2 || "",
				option3: menu.option3 || ""
			});
		}
	}, [menu]);

	const handleChange = e => {
		const { value } = e.target;
		setFormData(prevState => ({
			...prevState,
			...value
		}));
	};

	const handleFormValidity = valid => {
		setIsValid(valid);
	};

	return (
		<Dialog open onClose={() => onClose?.()}>
			<DialogTitle>
				{t(isEditMode ? "LABELS.editSurvey" : "LABELS.addSurvey")}
				<IconButton
					edge="end"
					color="inherit"
					onClick={onClose}
					aria-label="close"
					style={{ float: "right" }}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<SurveyForm
					formData={formData}
					onChangeForm={handleChange}
					onValidate={handleFormValidity} // Pass callback to receive validity
				/>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={() => onClose?.()}>
					{t("SETTINGS.cancel")}
				</MDButton>
				<MDButton
					variant="contained"
					color="info"
					onClick={() => {
						onAddSurvey({
							survey: {
								question: formData.question,
								option1: formData.option1,
								option2: formData.option2,
								option3: formData.option3
							}
						});
						onClose?.();
					}}
					disabled={!isValid}
				>
					{t(isEditMode ? "SETTINGS.edit" : "SETTINGS.add")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
};

export default DialogSurvey;
