import { uploadFileToS3 } from "../helpers/s3";

const useGetImageUploaded = () => {
	/**
	 * Uploads a file to S3.
	 * @param {File} file - The file to upload.
	 * @param {string} code - A unique identifier for the upload.
	 * @param {"attachment" | "inline"} type - The type of upload.
	 * @param {string} language - The language parameter.
	 * @returns {Promise<string>} The URL of the uploaded file.
	 */
	// Uploads a file to tmpfiles.org and returns the URL to the uploaded file.
	const uploadFile = async (file, code, language) => {
		const { fileObject } = await uploadFileToS3(file, code, "attachment", language);

		const body = new FormData();

		body.append("file", file);

		return fileObject.url;
	};

	return uploadFile;
};

export default useGetImageUploaded;
