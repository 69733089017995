import React from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

import WrapperPaperTag from "../WrapperPaperTag";

const Contact = ({ firstName, lastName, onClick, onClose, isEditMode, phoneNumber, email }) => {
	return (
		<WrapperPaperTag onClick={onClick} onClose={onClose} isEditMode={isEditMode} width={320}>
			<List
				sx={{
					width: "100%",
					maxWidth: 360,
					bgcolor: "background.paper"
				}}
			>
				<ListItem
					alignItems="center"
					sx={{
						display: "flex"
					}}
				>
					<ListItemAvatar>
						<Avatar alt={`${firstName} ${lastName}`} />
					</ListItemAvatar>
					<ListItemText primary={`${firstName} ${lastName}`} />
				</ListItem>
			</List>
		</WrapperPaperTag>
	);
};

export default Contact;
