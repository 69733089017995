import "../../style.css";
import { Alert, CircularProgress, Fade, Icon } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import SettingsActions from "redux-react/actions/settingsActions";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { LittleForm } from "components/Custom/LittleForm";
import i18n from "i18n";
import { createDictionarySkeleton } from "helpers/form";
import { formValidation } from "hooks/formHooks";

/**
 * Step 1: Selected the profile type
 */
const Step2CommonPart = ({
	validStep,
	onChange,
	channel,
	common,
	setCommon,
	errorUniqueFields,
	dictionaryChannelName
}) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [dictionary, setDictionary] = useState({});

	// Form validation
	const { valid, errors } = formValidation(common, dictionary);

	useEffect(() => {
		dispatch(
			SettingsActions.getDictionary(dictionaryChannelName, res => {
				const commonDictionary = res.dictionary ?? {};
				setDictionary(commonDictionary.items ?? {});

				const buildedCommon = createDictionarySkeleton(commonDictionary ?? {}, common);
				setCommon(buildedCommon);

				setLoading(false);
			})
		);
	}, []);

	/**
	 * If form is valid, then enable the next button
	 * else disable it
	 */
	useEffect(() => {
		validStep(valid);
	}, [valid]);

	return (
		<MDBox sx={{ height: "100%", width: "100%" }} display="flex" justifyContent="center">
			<MDBox sx={{ height: "100%", width: "60%" }} pt={5}>
				<MDBox display="flex" alignItems="center" pb={2}>
					<MDBox
						sx={{
							height: "3rem",
							width: "3rem"
						}}
						mr={1}
					>
						<MDBox
							component="img"
							borderRadius="md"
							src={channel.logoURL}
							alt={channel.logoURL}
							sx={{
								width: "100%",
								height: "auto",
								objectFit: "contain"
							}}
						/>
					</MDBox>

					<MDTypography variant="h4">{`${i18n.t("CHANNEL.configureChannel")} ${channel.name ? channel.name : ""}`}</MDTypography>
				</MDBox>

				{loading ? (
					<MDBox
						display="flex"
						alignItems="center"
						justifyContent="center"
						sx={{
							height: "auto",
							width: "100%"
						}}
					>
						<CircularProgress color="info" />
					</MDBox>
				) : (
					<>
						<LittleForm
							object={dictionary}
							hiddenFields={channel?.hiddenFields}
							metadatasSkeleton={common}
							errorUniqueFields={errorUniqueFields}
							handleChange={(path, value) => {
								onChange(path, value);
							}}
						/>
						<Fade in={Object.keys(errors).length > 0}>
							<Alert sx={{ mt: 1 }} icon={<Icon color="error">error</Icon>} severity="error">
								{Object.keys(errors).length > 0 && (
									<MDTypography variant="h6" fontSize="small" color="error">
										{`${errors[Object.keys(errors)[0]].label}: ${errors[Object.keys(errors)[0]].error}`}
									</MDTypography>
								)}
							</Alert>
						</Fade>
					</>
				)}
			</MDBox>
		</MDBox>
	);
};

export default Step2CommonPart;
