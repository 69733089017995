import WrapperPaperTag from "../WrapperPaperTag";
import LinkCard from "./LinkCard";

const TypologyLink = ({ link, description, title, onClick, onClose, isEditMode }) => {
	return (
		<WrapperPaperTag onClick={onClick} onClose={onClose} isEditMode={isEditMode}>
			<LinkCard title={title} description={description} link={link} />
		</WrapperPaperTag>
	);
};

export default TypologyLink;
