import "../../style.css";
import lod_ from "lodash";
import { Alert, CircularProgress, Fade, Icon } from "@mui/material";
import { createDictionarySkeleton } from "helpers/form";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import SettingsActions from "redux-react/actions/settingsActions";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { LittleForm } from "components/Custom/LittleForm";

import i18n from "i18n";
import GoogleConfiguration from "./specific/googleReview";
import MicrosoftConfiguration from "./specific/microsoft";
import MetaConfiguration from "./specific/meta";
import ShopifyConfiguration from "./specific/shopify";
import { formValidation } from "hooks/formHooks";

/**
 * Step 1: Selected the profile type
 */
const Step3ConfigPart = ({
	validStep,
	onChange,
	commonPart,
	config,
	setConfigPart,
	channel,
	channelSubType = {},
	extraDatas,
	setExtraDatas
}) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [dictionary, setDictionary] = useState({});

	const [CMConfig, setCMConfig] = useState(undefined);

	// Form validation
	const { valid, errors } = formValidation(config, dictionary);

	useEffect(() => {
		const getConfigDictionarySuccess = dictionary => {
			if (!lod_.isEmpty(dictionary)) {
				setDictionary(dictionary.items);
				let buildedConfig = createDictionarySkeleton(dictionary, config);

				// setConfigSkeleton(buildedConfig);
				setConfigPart(buildedConfig);
			}

			setLoading(false);
		};

		const getConfigDictionary = code => {
			dispatch(
				SettingsActions.getDictionary(
					code,
					res => {
						getConfigDictionarySuccess(res.dictionary ?? {});
					},
					() => {}
				)
			);
		};

		if (channelSubType.codeDictionary) {
			getConfigDictionary(channelSubType.codeDictionary);
		} else {
			setLoading(false);
		}
	}, []);

	const fullScreen = () => {
		const FULLSCREEN = [];
		return FULLSCREEN.includes(channel.subType);
	};

	/**
	 * Get a specfic part depending on the channel type
	 * @returns
	 */
	const getConfigurationContent = () => {
		const subtypeCMConfig = {
			GOGR: "google",
			gmail: "google",
			MSFT: "microsoft",
			FB: "facebook",
			IS: "facebook",
			WA: "facebook",
			SHFY: "shopify"
		};

		const { subType } = channel;

		// Only get the config if it's not already loaded and if the subtype is in the list
		if (CMConfig === undefined && subtypeCMConfig[subType]) {
			setCMConfig(null);
			dispatch(
				SettingsActions.getCMConfig(subtypeCMConfig[subType], res => {
					setCMConfig(res.config);
				})
			);
		}

		if (loading || (!CMConfig && subtypeCMConfig[subType])) {
			return (
				<MDBox
					display="flex"
					alignItems="center"
					justifyContent="center"
					sx={{
						height: "auto",
						width: "100%"
					}}
				>
					<CircularProgress color="info" />
				</MDBox>
			);
		}

		switch (subType) {
			// Google Review
			case "GOGR": {
				return (
					<GoogleConfiguration
						googleConfig={CMConfig}
						channelCode={commonPart.code}
						config={config}
						onChange={onChange}
						setConfigPart={setConfigPart}
						type="GOGR"
						googleType="mybusiness"
						dictionary={dictionary}
						configSkeleton={config}
						extraDatas={extraDatas}
						setExtraDatas={setExtraDatas}
					/>
				);
			}
			// Gmail
			case "gmail": {
				return (
					<GoogleConfiguration
						googleConfig={CMConfig}
						channelCode={commonPart.code}
						config={config}
						onChange={onChange}
						setConfigPart={setConfigPart}
						type="gmail"
						googleType="gmail"
						dictionary={dictionary}
						configSkeleton={config}
						extraDatas={extraDatas}
						setExtraDatas={setExtraDatas}
					/>
				);
			}
			// Microsoft mails
			case "MSFT": {
				return (
					<MicrosoftConfiguration
						microsoftConfig={CMConfig}
						channelCode={commonPart.code}
						config={config}
						onChange={onChange}
						setConfigPart={setConfigPart}
						type="office365"
						dictionary={dictionary}
						configSkeleton={config}
						extraDatas={extraDatas}
						setExtraDatas={setExtraDatas}
					/>
				);
			}
			case "FB":
			case "IS":
			case "WA": {
				return (
					<MetaConfiguration
						metaConfig={CMConfig}
						channelCode={commonPart.code}
						config={config}
						onChange={onChange}
						setConfigPart={setConfigPart}
						subType={subType}
						dictionary={dictionary}
						configSkeleton={config}
						extraDatas={extraDatas}
						setExtraDatas={setExtraDatas}
					/>
				);
			}
			case "SHFY": {
				return (
					<ShopifyConfiguration
						shopifyConfig={CMConfig}
						channelCode={commonPart.code}
						config={config}
						onChange={onChange}
						setConfigPart={setConfigPart}
						dictionary={dictionary}
						configSkeleton={config}
						extraDatas={extraDatas}
						setExtraDatas={setExtraDatas}
					/>
				);
			}
			// Default
			default:
				return (
					<>
						{!loading && lod_.isEmpty(dictionary) && (
							<MDBox display="flex" justifyContent="center">
								<MDTypography variant="body2" sx={{ opacity: 0.7 }}>
									{i18n.t("CHANNEL.noConfigurationAvailable")}
								</MDTypography>
							</MDBox>
						)}

						{loading ? (
							<MDBox
								display="flex"
								alignItems="center"
								justifyContent="center"
								sx={{
									height: "auto",
									width: "100%"
								}}
							>
								<CircularProgress color="info" />
							</MDBox>
						) : (
							<>
								<LittleForm
									object={dictionary}
									metadatasSkeleton={config}
									handleChange={(path, value) => {
										onChange(path, value);
									}}
								/>
								<Fade in={Object.keys(errors).length > 0}>
									<Alert sx={{ mt: 1 }} icon={<Icon color="error">error</Icon>} severity="error">
										{Object.keys(errors).length > 0 && (
											<MDTypography variant="h6" fontSize="small" color="error">
												{`${errors[Object.keys(errors)[0]].label}: ${errors[Object.keys(errors)[0]].error}`}
											</MDTypography>
										)}
									</Alert>
								</Fade>
							</>
						)}
					</>
				);
		}
	};

	/**
	 * If form is valid, then enable the next button
	 * else disable it
	 */
	useEffect(() => {
		validStep(valid);
	}, [valid]);

	return (
		<MDBox sx={{ height: "100%", width: "100%" }} display="flex" justifyContent="center">
			<MDBox
				sx={{
					height: "100%",
					width: fullScreen() ? "100%" : "60%",
					display: "flex",
					flexDirection: "column"
				}}
				pt={fullScreen() ? 1 : 5}
			>
				{!fullScreen() && (
					<MDBox display="flex" alignItems="center" pb={2}>
						<MDBox
							sx={{
								height: "3rem",
								width: "3rem"
							}}
							mr={1}
						>
							<MDBox
								component="img"
								borderRadius="md"
								src={channel.logoURL}
								alt={channel.logoURL}
								sx={{
									width: "100%",
									height: "auto",
									objectFit: "contain"
								}}
							/>
						</MDBox>

						<MDTypography variant="h4">{`${i18n.t("CHANNEL.configureChannel")} ${channel.name ? channel.name : ""}`}</MDTypography>
					</MDBox>
				)}
				{getConfigurationContent()}
			</MDBox>
		</MDBox>
	);
};

export default Step3ConfigPart;
