import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import { Link as LinkIcon } from "@mui/icons-material";

const LinkCard = ({ title, description, link }) => {
	return (
		<Card
			sx={{
				maxWidth: 320,
				borderRadius: "8px",
				overflow: "hidden",
				boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)"
			}}
		>
			<Box
				sx={{
					backgroundColor: "#f0f0f0",
					position: "relative",
					height: "80px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				<LinkIcon
					sx={{
						width: 80,
						height: 80
					}}
				/>
			</Box>
			<Box
				sx={{
					color: "white",
					padding: "16px"
				}}
			>
				<Typography variant="h6" component="div" fontWeight="bold">
					{title}
				</Typography>
				<Typography variant="body2" sx={{ mt: 0.5 }}>
					{description}
				</Typography>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						mt: 2,
						gap: 1,
						marginRight: "5px"
					}}
				>
					<Avatar
						sx={{
							width: 16,
							height: 16,
							backgroundColor: "#0099ff",
							fontSize: "10px"
						}}
					>
						↗
					</Avatar>
					<Link
						href={link}
						target="_blank"
						rel="noopener noreferrer"
						sx={{
							color: "#0099ff",
							fontSize: "14px",
							textDecoration: "none",
							"&:hover": {
								textDecoration: "underline"
							},
							marginRight: "10px"
						}}
					>
						{link}
					</Link>
				</Box>
			</Box>
		</Card>
	);
};

export default LinkCard;
