import "./MentionList.css";
import { forwardRef, useEffect, useImperativeHandle, useState, useMemo } from "react";
import { t } from "i18next";
import { FaCaretRight } from "react-icons/fa";
import useMapDictionary from "../../../../hooks/useMapDictionary";

// Forward reference for MenuList component
const MenuList = forwardRef((props, ref) => {
	const [hoveredMenu, setHoveredMenu] = useState([]);

	const { mappedDictionary } = useMapDictionary(props.dictionary);

	const upHandler = () => {};
	const downHandler = () => {};
	const enterHandler = () => {};

	useEffect(() => {}, [props.items]);

	useImperativeHandle(ref, () => ({
		onKeyDown: ({ event }) => {
			if (event.key === "ArrowUp") {
				upHandler();
				return true;
			}
			if (event.key === "ArrowDown") {
				downHandler();
				return true;
			}
			if (event.key === "Enter") {
				enterHandler();
				return true;
			}
			return false;
		}
	}));

	// Recursive function to render menu items
	const renderMenuItems = (items, level = 0, parentsItem = []) => {
		if (!items || items.length === 0) return null; // Base case to terminate recursion

		return items.map((item, index) => (
			<div
				key={item.id}
				className={`menu-item level-${level}`}
				onMouseEnter={() => {
					if (item.child && item.child.length > 0) {
						setHoveredMenu(prev => [...prev, item]);
					}
				}}
				onMouseLeave={() => {
					setHoveredMenu(hoveredMenu.filter(menu => menu.id !== item.id));
				}}
			>
				<button
					onClick={() => {
						if (!item.child || item.child.length === 0) {
							const title = parentsItem.length
								? parentsItem.map(p => p.title).join(" / ") + " / " + item.title
								: item.title;
							props.onItemClick({ title });
							props.editorBlock.insertInlineContent([
								{ type: "mention", props: { user: title } },
								" "
							]);
						}
					}}
					className={`slash-menu-item ${index === 0 ? " selected" : ""}`}
					type="button"
				>
					<span>{item.title}</span>
					{item.child && item.child.length > 0 && <FaCaretRight />}
				</button>

				{hoveredMenu.some(value => value.id === item.id) && item.child && (
					<div className="submenu">
						{renderMenuItems(item.child, level + 1, [...parentsItem, item])}
					</div>
				)}
			</div>
		));
	};

	return (
		<div className="dropdown-menu">
			{props.items.length ? (
				renderMenuItems(mappedDictionary, 0, [])
			) : (
				<div className="item">{t("COMPONENT.ANSWERTYPE.BLOCKNOTE.noResult")}</div>
			)}
		</div>
	);
});

export default MenuList;
