import React from "react";
import { uploadFileToS3 } from "../../../../helpers/s3";

// Helper function to create attachment object
const createAttachment = (file, url, isUploading, uploadError = false, key = null) => ({
	url,
	mimeType: file.type,
	name: file.name,
	isUploading,
	uploadError,
	key
});

// Helper function to create payload structure
const createPayload = attachments => ({
	document: {
		payload: {
			payload: { attachments }
		}
	}
});

const FileUploader = ({ onTypeChange, fileInputRef }) => {
	const handleFileChange = async event => {
		const file = event.target.files[0];
		if (!file) return;

		const tempAnswerItemID = `temp_${Date.now()}`;
		const localUrl = URL.createObjectURL(file);

		try {
			// Show uploading state
			const uploadingAttachment = createAttachment(file, localUrl, true);
			onTypeChange("document", createPayload([uploadingAttachment]));

			// Upload to S3
			const uploadResult = await uploadFileToS3(file, tempAnswerItemID, "document", "fr");

			if (uploadResult) {
				const { fileObject } = uploadResult;
				const successAttachment = createAttachment(
					file,
					fileObject.url,
					false,
					false,
					fileObject.key
				);
				onTypeChange("document", createPayload([successAttachment]));
			}
		} catch (error) {
			const errorAttachment = createAttachment(file, localUrl, false, true);
			onTypeChange("document", createPayload([errorAttachment]));
		} finally {
			// Clean up object URL to prevent memory leak
			URL.revokeObjectURL(localUrl);
		}
	};

	return (
		<input
			type="file"
			ref={fileInputRef}
			style={{ display: "none" }}
			onChange={handleFileChange}
			multiple={false}
		/>
	);
};

export default FileUploader;
