/* eslint-disable import/no-import-module-exports */
/**
 *--------------------------------------------------
 *
 * OPERATIONS DOC :
 * https://jsonlogic.com/operations.html
 *
 *--------------------------------------------------
 */
/* FOR ALL TYPE */
const COMP_DEFAULT = [
	{
		type: "title",
		title: "Opérateurs de base"
	},
	{
		code: "string_contain",
		operator: "in",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.in",
		type: "field"
	},
	{
		code: "element_is_contains_in_array",
		operator: "in",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.inArrayValue",
		type: "arrayList"
	},

	{
		code: "array_notequal_element",
		operator: "!",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.ne",
		type: "arrayList"
	},
	{
		code: "field_notequal_element",
		operator: "!=",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.ne",
		type: "fieldSpe"
	},
	// {
	// 	code: "string_not_equal",
	// 	operator: "!=",
	// 	label: "SETTINGS.CHARTS.COMPUTE.METHODS.nenu"
	// },
	// {
	// 	code: "string_equal",
	// 	operator: "==",
	// 	label: "SETTINGS.CHARTS.COMPUTE.METHODS.eqnu"
	// },
	{
		code: "array_equal_element",
		operator: "in",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.eq",
		type: "arrayList"
	},
	{
		code: "field_equal_element",
		operator: "==",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.eq",
		type: "fieldSpe"
	},
	{
		code: "element_exist",
		operator: "!=",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.elementExist",
		value: null
	},
	{
		code: "element_not_exist",
		operator: "==",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.elementNotExist",
		value: null
	},
	/*
	 * Special for "isArray"
	 */
	{
		code: "element_is_in_array",
		operator: "or",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.eqInArray",
		type: "array"
	},
	{
		code: "array_not_equals",
		operator: "!",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.neqInArray",
		type: "array"
	},
	{
		code: "element_exist",
		operator: "!=",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.elementExist",
		value: null,
		type: "array"
	},
	{
		code: "element_not_exist",
		operator: "==",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.elementNotExist",
		value: null,
		type: "array"
	},
	/*  */
	{
		type: "divider"
	},
	{
		type: "title",
		title: "Opérateurs spécifiques"
	}
];
/* STRING */
const COMP_STRING = [...COMP_DEFAULT];
/* BOOLEAN */

const COMP_BOOLEAN = [
	{
		code: "number_equal",
		operator: "==",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.lt",
		type: "boolean"
	}
];
/* NUMBER */
const COMP_NUMBER = [
	...COMP_DEFAULT,

	{
		code: "number_less_than",
		operator: "<",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.lt",
		type: "field"
	},

	{
		code: "number_less_than_or_equal",
		operator: "<=",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.lte",
		type: "field"
	},
	{
		code: "number_greater_than",
		operator: ">",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.gt",
		type: "field"
	},
	{
		code: "number_greater_than_or_equal",
		operator: ">=",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.gte",
		type: "field"
	}
];
/* CODE */
const COMP_CODE = [...COMP_DEFAULT];
/* DATE */
const COMP_DATE = [
	...COMP_DEFAULT,
	{
		code: "date_equal",
		operator: ">",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.gt",
		type: "timestamp"
	},
	{
		code: "date_not_equal",
		operator: "<",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.lt",
		type: "timestamp"
	},
	{
		code: "date_equal",
		operator: ">",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.gt",
		type: "date"
	},
	{
		code: "date_not_equal",
		operator: "<",
		label: "SETTINGS.CHARTS.COMPUTE.METHODS.lt",
		type: "date"
	}
];

export default {
	COMP_STRING,
	COMP_NUMBER,
	COMP_BOOLEAN,
	COMP_CODE,
	COMP_DATE
};
