/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
/* eslint-disable dot-notation */
/* eslint-disable no-unreachable */
import lod_ from "lodash";
import { Drawer, Icon, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { t } from "i18next";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDBadge from "components/Basics/MDBadge";
import MDTypography from "components/Basics/MDTypography";
import { queryDictionary } from "helpers/dictionary";

import { useEffect, useMemo, useState } from "react";
import { socket } from "redux-react/middleware/ws";
import { display } from "redux-react/reducers/snackBarReducer";
import FormActions from "redux-react/actions/formAction";
import { useDispatch } from "react-redux";
import EditShopForm from "./editShopForm";
import LeftPannel from "./components/LeftPannel";

const COLLECTION = "externalGooglePlace";

const GoogleScrapingConfiguration = ({ validStep }) => {
	const [googlePlaceDictionary, setGooglePlaceDictionary] = useState({});

	/**
	 * Datas used to build the table
	 * used to be dynamic in the future
	 */
	const tableConfig = useMemo(
		() => ({
			rows: [],
			columns: [
				{ accessor: "active", Header: "Active" },
				{ accessor: "configured", Header: "Opérationnelle" },
				{ accessor: "code", Header: "Code" },
				{ accessor: "name", Header: "Magasin" },
				{ accessor: "brand", Header: "Marque" }
			]
		}),
		[]
	);

	const attributes = ["_id", "code", "active", "configured", "name", "brand"];
	const attributeToDisplay = ["active", "configured", "name", "brand"];

	/* end of datas */

	const dispatch = useDispatch();

	const [reloadTable, setReloadTable] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [drawerChild, setDrawerChild] = useState(null);

	const [filters, setFilters] = useState({});

	const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
	const [selectedRows, setSelectedRows] = useState([]);

	/**
	 * Reload the table
	 */
	const reloadTableHandler = () => {
		setReloadTable(!reloadTable);
	};

	/**
	 * Close the drawer
	 */
	const closeDrawer = () => {
		setOpenDrawer(false);
		setDrawerChild(null);
	};

	/**
	 * Add a shop
	 * @param {props.datas} datas - New datas
	 * @returns {void}
	 */
	const onSaveAddShop = ({ datas }) => {
		const payload = { values: datas, target: COLLECTION, unique: { code: datas.code } };

		const onSuccess = res => {
			dispatch(
				display({
					message: "Magasin ajouté avec succès",
					type: "success"
				})
			);

			// Search the shop
			socket.emit("search_shop_in_google_places", {
				code: datas.code,
				name: datas.name,
				url: datas.url
			});

			reloadTableHandler();
			closeDrawer();
		};

		const onError = err => {
			const message = err?.response?.data?.error?.message || "Erreur lors de l'ajout du magasin";
			dispatch(
				display({
					message,
					type: "error"
				})
			);
		};

		dispatch(FormActions.addItemEmpty(payload, onSuccess, onError));
	};

	/**
	 * Edit a shop
	 * @param {props.datas} datas - New datas
	 * @param {props.shop} shop - Shop to edit
	 * @returns {void}
	 */
	const onSaveEditShop = ({ datas, shop }) => {
		const payload = { values: datas, target: COLLECTION, unique: {} };

		const onSuccess = res => {
			dispatch(
				display({
					message: "Magasin modifié avec succès",
					type: "success"
				})
			);

			reloadTableHandler();
			closeDrawer();
		};

		const onError = err => {
			const message = err?.response?.data?.error?.message || "Erreur lors de l'édition du magasin";
			dispatch(
				display({
					message,
					type: "error"
				})
			);
		};

		dispatch(FormActions.updateItem(shop["_id"], payload, onSuccess, onError));
	};

	/**
	 * Delete a shop
	 * @param {object} shop - Shop to delete
	 * @returns {void}
	 */
	const onDeleteShop = shop => {
		const shopID = shop._id;
		if (!shopID) return;

		const onSuccess = res => {
			dispatch(
				display({
					message: t("FORMS.deleteSuccess"),
					type: "success"
				})
			);

			reloadTableHandler();
			closeDrawer();
		};
		dispatch(
			FormActions.deleteItem(
				shopID,
				COLLECTION,
				{
					delete: true
				},
				onSuccess
			)
		);
	};

	/**
	 * Update many shops
	 * @param {<string>} codes - Codes of the shops to update
	 * @param {object} update - Update to apply
	 */
	const updateManyShops = (codes, update = {}) => {
		const onSuccess = () => {
			reloadTableHandler();
		};

		dispatch(
			FormActions.updateManyItems(
				COLLECTION,
				{
					code: { $in: codes }
				},
				update,
				onSuccess
			)
		);
	};

	/**
	 * Delete many shops
	 * @param {<string>} codes - Codes of the shops to delete
	 */
	const deleteManyShops = codes => {
		const onSuccess = () => {
			reloadTableHandler();
		};

		dispatch(
			FormActions.deleteManyItems(
				COLLECTION,
				{
					code: { $in: codes }
				},
				onSuccess
			)
		);
	};

	/**
	 * WS Result when a shop is found in google places
	 */
	const handleRefreshExternalGooglePlacesList = () => {
		reloadTableHandler();
	};

	const getDictionary = async code => {
		const dictionaryPlace = await queryDictionary(dispatch, code);
		setGooglePlaceDictionary(dictionaryPlace?.items ?? {});
	};

	const HEADER_ACTIONS = [
		<MDBox
			display="flex"
			style={{
				alignItems: "stretch",
				height: "100%"
			}}
		>
			{/* Add shop */}
			<MDButton
				size="small"
				sx={{ ml: 1 }}
				variant="contained"
				color="info"
				onClick={e => {
					setOpenDrawer(true);
					setDrawerChild(
						<EditShopForm
							dictionary={googlePlaceDictionary}
							datas={{}}
							onClose={closeDrawer}
							onSave={onSaveAddShop}
							onDelete={() => {}}
							type="create"
						/>
					);
				}}
			>
				<Icon>add</Icon>&nbsp;Ajouter
			</MDButton>
			{/* Import list */}
			<MDButton
				sx={{ ml: 1 }}
				disabled
				variant="contained"
				color="info"
				size="small"
				onClick={e => {}}
			>
				<Icon>upload</Icon>&nbsp;Importer
			</MDButton>
			{/* Massives actions */}
			<MDBadge color="error" badgeContent={selectedRows.length} circular size="xs">
				<MDButton
					sx={{ ml: 1 }}
					color="info"
					onClick={e => {
						setActionsAnchorEl(e.currentTarget);
					}}
					disabled={selectedRows.length === 0}
				>
					<Icon>list_alt</Icon>&nbsp;Actions
				</MDButton>

				<Menu
					id="simple-actions-menu"
					anchorEl={actionsAnchorEl}
					open={Boolean(actionsAnchorEl)}
					onClose={e => {
						setActionsAnchorEl(null);
						e.stopPropagation();
						e.preventDefault();
					}}
				>
					{/* Options */}
					<MenuItem
						onClick={() => {
							updateManyShops(
								selectedRows.map(row => row.code),
								{ $set: { active: true } }
							);
							setActionsAnchorEl(null);
							setSelectedRows([]);
						}}
					>
						<Icon fontSize="medium">sync</Icon>&nbsp; Tout activer
					</MenuItem>
					<MenuItem
						onClick={() => {
							updateManyShops(
								selectedRows.map(row => row.code),
								{ $set: { active: false } }
							);
							setActionsAnchorEl(null);
							setSelectedRows([]);
						}}
					>
						<Icon fontSize="medium">sync_disabled</Icon>&nbsp; Tout désactiver
					</MenuItem>
					<MenuItem
						onClick={() => {
							deleteManyShops(selectedRows.map(row => row.code));
							setActionsAnchorEl(null);
							setSelectedRows([]);
						}}
						style={{
							color: "red"
						}}
					>
						<Icon fontSize="medium">delete</Icon>&nbsp;Supprimer
					</MenuItem>
				</Menu>
			</MDBadge>
		</MDBox>
	];

	const DATATABLE_ACTIONS = [
		<IconButton
			handleclick={({ values: shop }, e) => {
				e.stopPropagation();
				e.preventDefault();
				setOpenDrawer(true);
				setDrawerChild(
					<EditShopForm
						dictionary={googlePlaceDictionary}
						code={shop.code}
						onClose={closeDrawer}
						onSave={onSaveEditShop}
						onDelete={onDeleteShop}
						type="edit"
					/>
				);
			}}
		>
			<Tooltip placement="top" title={t("SETTINGS.edit")}>
				<Icon>edit</Icon>
			</Tooltip>
		</IconButton>
	];

	useEffect(() => {
		socket.on("refresh_external_google_places_list", handleRefreshExternalGooglePlacesList);

		return () => {
			socket.off("refresh_external_google_places_list", handleRefreshExternalGooglePlacesList);
		};
	}, [reloadTable]);

	useEffect(() => {
		validStep();
		getDictionary("externalGooglePlace");
	}, []);

	if (!googlePlaceDictionary || lod_.isEmpty(googlePlaceDictionary)) return null;

	return (
		<MDBox
			display="flex"
			flexDirection="column"
			alignItems="center"
			sx={{
				height: "100%"
			}}
		>
			<MDBox flex="1" sx={{ width: "98%", position: "relative", height: "100%" }} display="flex">
				{/* Left */}
				<LeftPannel dictionary={googlePlaceDictionary} filters={filters} setFilters={setFilters} />
				{/* Main */}
				<MDBox flex="5" display="flex" flexDirection="column" sx={{ height: "100%" }}>
					<DefaultDataTable
						// Checkbox
						checkbox
						selectedRows={selectedRows}
						handleSelection={values => {
							setSelectedRows(values);
						}}
						isSelectedRow={row => {
							const existingRow = [].find(r => r.code === row.code);
							return Boolean(existingRow);
						}}
						//
						list={{
							request: {
								collection: COLLECTION,
								attributes
							},
							filters: {},
							options: {}
						}}
						pagination={{
							defaultEntrie: 20,
							entriesPerPage: [15, 20, 40, 50, 100]
						}}
						noMargin
						noHeader
						headerAction={HEADER_ACTIONS}
						filters={filters}
						reloadTable={reloadTable}
						canSearch
						table={tableConfig}
						display={attributeToDisplay}
						actions={DATATABLE_ACTIONS}
					/>

					<Drawer
						anchor="right"
						open={openDrawer}
						onClose={() => closeDrawer()}
						style={{
							zIndex: 1300
						}}
						PaperProps={{
							sx: { width: "40%" }
						}}
					>
						<MDBox p={2} style={{ height: "100%" }}>
							{drawerChild}
						</MDBox>
					</Drawer>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default GoogleScrapingConfiguration;
