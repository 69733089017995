import React from "react";
import { t } from "i18next";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import { useFormik } from "formik";
import * as Yup from "yup";

const LinkForm = ({ formData, onChangeForm, onUpdateStatusForm }) => {
	// Validation schema using Yup
	const validationSchema = Yup.object({
		link: Yup.string().url(t("FORMS.ERRORS.invalidUrl")).required(t("FORMS.ERRORS.isRequired")),
		description: Yup.string().required(t("FORMS.ERRORS.isRequired")),
		title: Yup.string().max(100, t("FORMS.ERRORS.titleMaxLength")),
		footer: Yup.string().max(100, t("FORMS.ERRORS.footerMaxLength")),
		buttonText: Yup.string().max(50, t("FORMS.ERRORS.buttonTextMaxLength"))
	});

	const formik = useFormik({
		initialValues: {
			link: formData.link || "",
			description: formData.description || "",
			title: formData.title || "",
			footer: formData.footer || "",
			buttonText: formData.buttonText || ""
		},
		validationSchema: validationSchema,

		//	enableReinitialize: true, // This ensures form updates when formData prop changes
		validateOnMount: true
	});

	// Handle field changes to maintain compatibility with parent component
	const handleChange = e => {
		formik.handleChange(e);
		onChangeForm(e); // Keep the parent's onChangeForm functionality
	};

	React.useEffect(() => {
		onUpdateStatusForm(formik.isValid);
	}, [formik.isValid]);

	return (
		<Container maxWidth="md">
			<Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 3 }}>
				<Grid container spacing={2}>
					{/* URL Field */}
					<Grid size={{ xs: 12 }}>
						<TextField
							fullWidth
							label={t("LABELS.url")}
							name="link"
							value={formik.values.link}
							onChange={handleChange}
							onBlur={formik.handleBlur}
							variant="outlined"
							required
							error={formik.touched.link && Boolean(formik.errors.link)}
							helperText={formik.touched.link && formik.errors.link}
						/>
					</Grid>

					{/* Description Field */}
					<Grid size={{ xs: 12 }}>
						<TextField
							fullWidth
							label={t("LABELS.description")}
							name="description"
							value={formik.values.description}
							onChange={handleChange}
							onBlur={formik.handleBlur}
							variant="outlined"
							required
							error={formik.touched.description && Boolean(formik.errors.description)}
							helperText={formik.touched.description && formik.errors.description}
						/>
					</Grid>

					{/* Title Field */}
					<Grid size={{ xs: 12, md: 6 }}>
						<TextField
							fullWidth
							label={t("LABELS.title")}
							name="title"
							value={formik.values.title}
							onChange={handleChange}
							onBlur={formik.handleBlur}
							variant="outlined"
							error={formik.touched.title && Boolean(formik.errors.title)}
							helperText={formik.touched.title && formik.errors.title}
						/>
					</Grid>

					{/* Footer Field */}
					<Grid size={{ xs: 12, md: 6 }}>
						<TextField
							fullWidth
							label={t("LABELS.footer")}
							name="footer"
							value={formik.values.footer}
							onChange={handleChange}
							onBlur={formik.handleBlur}
							variant="outlined"
							error={formik.touched.footer && Boolean(formik.errors.footer)}
							helperText={formik.touched.footer && formik.errors.footer}
						/>
					</Grid>

					{/* Button Text Field */}
					<Grid size={{ xs: 12 }}>
						<TextField
							fullWidth
							label={t("LABELS.buttonText")}
							name="buttonText"
							value={formik.values.buttonText}
							onChange={handleChange}
							onBlur={formik.handleBlur}
							variant="outlined"
							error={formik.touched.buttonText && Boolean(formik.errors.buttonText)}
							helperText={formik.touched.buttonText && formik.errors.buttonText}
						/>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
};

export default LinkForm;
