import {
	AttachFile,
	List,
	AddLink,
	Contacts,
	AddLocation,
	Image as ImageIcon,
	Videocam as VideocamIcon,
	PictureAsPdf as PictureAsPdfIcon,
	Description as DescriptionIcon,
	InsertDriveFile as InsertDriveFileIcon,
	Audiotrack as AudiotrackIcon,
	Archive as ArchiveIcon,
	AddLocationAlt
} from "@mui/icons-material";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";

export const answerTypes = [
	{ type: "text", icon: <FormatColorTextIcon />, label: "Texte" },
	{ type: "document", icon: <AttachFile />, label: "ANSWERS_FORMAT.document" },
	{ type: "survey", icon: <List />, label: "ANSWERS_FORMAT.menuyesno" },
	{ type: "link", icon: <AddLink />, label: "ANSWERS_FORMAT.link" },
	{ type: "contact", icon: <Contacts />, label: "ANSWERS_FORMAT.contact" },
	{ type: "location", icon: <AddLocation />, label: "ANSWERS_FORMAT.location" },
	{ type: "locationRequest", icon: <AddLocationAlt />, label: "ANSWERS_FORMAT.location_request" }
];

export const REACT_GOOGLE_MAP_API_KEY = "AIzaSyDjVhn_bwdld6rb3l1SfdzhJh4_TRrhDqU";

export const ANSWERS_CONVERSATION_FORMAT = {
	document: "document",
	menuyesno: "survey",
	richtext: "text",
	location: "location",
	link: "link",
	contact: "contact",
	locationRequest: "locationRequest"
};
export const fileTypeIcons = new Map([
	["image", <ImageIcon key="image" fontSize="small" />],
	["video", <VideocamIcon key="video" fontSize="small" />],
	["pdf", <PictureAsPdfIcon key="pdf" fontSize="small" />],
	["word", <DescriptionIcon key="word" fontSize="small" />],
	["doc", <DescriptionIcon key="doc" fontSize="small" />],
	["audio", <AudiotrackIcon key="audio" fontSize="small" />],
	["mp3", <AudiotrackIcon key="mp3" fontSize="small" />],
	["wav", <AudiotrackIcon key="wav" fontSize="small" />],
	["zip", <ArchiveIcon key="zip" fontSize="small" />],
	["rar", <ArchiveIcon key="rar" fontSize="small" />],
	["text", <InsertDriveFileIcon key="text" fontSize="small" />],
	["default", <InsertDriveFileIcon key="default" fontSize="small" />]
]);
