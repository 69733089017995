import { Autocomplete, Chip, Tooltip } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import { regexMail } from "helpers/utilities";
import { useState } from "react";
import lod_ from "lodash";
import i18n from "i18n";
import WhiteListItem from "./WhiteListItem";

const InputEmail = ({
	label,
	value,
	onChange,
	item,
	regexEmail,
	listOfApis,
	path,
	disabled,
	error
}) => {
	const [viewInvalidEmail, setViewInvalidEmail] = useState("");
	const [viewInvalid, setViewInvalid] = useState("");

	let isArray = item.isArray || false;
	let whitelist = item.whitelist || [];

	return (
		<MDBox>
			{isArray ? (
				<Tooltip
					open={viewInvalid === path}
					title={viewInvalidEmail}
					placement="bottom"
					disableFocusListener
					disableHoverListener
					disableTouchListener
				>
					<Autocomplete
						disabled={disabled}
						value={lod_.isArray(value) ? value : []}
						onChange={(event, newValue) => {
							const isValid = newValue.every(val => val === "" || regexMail(val, regexEmail));
							if (isValid) {
								if (lod_.isEmpty(whitelist)) {
									onChange(newValue);
								} else {
									const stringValuesWhite = newValue.filter(val => whitelist.includes(val));
									if (stringValuesWhite.length === newValue.length) {
										onChange(stringValuesWhite);
									} else {
										setViewInvalid(path);
										setViewInvalidEmail(i18n.t("FORMS.whitelistInvalidList"));

										setTimeout(() => {
											setViewInvalid("");
											setViewInvalidEmail("");
										}, 3000);
									}
								}
							} else {
								setViewInvalid(path);
								setViewInvalidEmail(i18n.t("FORMS.emailInvalidList"));
								setTimeout(() => {
									setViewInvalid("");
									setViewInvalidEmail("");
								}, 3000);
							}
						}}
						multiple
						id="tags-filled"
						options={whitelist}
						freeSolo
						renderTags={(tags, getTagProps) => {
							return tags.map((option, index) => {
								return <Chip label={option} {...getTagProps({ index })} />;
							});
						}}
						renderInput={params => <MDInput {...params} label={label} />}
					/>
				</Tooltip>
			) : (
				<WhiteListItem
					label={label}
					value={value}
					onChange={onChange}
					item={item}
					listOfApis={listOfApis}
					disabled={disabled}
					error={error}
				/>
			)}
		</MDBox>
	);
};
export default InputEmail;
