import * as React from "react";

import { t } from "i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import MDButton from "components/Basics/MDButton";
import GoogleMap from "../GoogleMap";

const DialogGoogleMap = ({ onClose, onAddLocation, position: possistionProps, isEditMode }) => {
	const [position, setPosition] = React.useState({
		lat: 48.8566, // Default to Paris
		lng: 2.3522,
		name: "Paris, France",
		address: "Paris, France"
	});

	React.useEffect(() => {
		if (possistionProps) {
			setPosition({
				lat: possistionProps?.latitude, // Default to Paris
				lng: possistionProps?.longitude,
				name: possistionProps?.name,
				address: possistionProps?.address
			});
		}
	}, [possistionProps]);

	return (
		<Dialog fullScreen open>
			<DialogContent>
				<GoogleMap onChangePosition={newPosition => setPosition(newPosition)} position={position} />
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={onClose}>
					{t("SETTINGS.cancel")}
				</MDButton>
				<MDButton
					variant="contained"
					color="info"
					onClick={() => {
						onAddLocation?.({
							location: {
								latitude: position.lat,
								longitude: position.lng,
								name: position.name, // Optional
								address: position.address // Optional
							}
						});
						onClose?.();
					}}
				>
					{t(isEditMode ? "SETTINGS.edit" : "SETTINGS.add")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
};

export default DialogGoogleMap;
