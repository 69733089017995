import React, { useState, useEffect } from "react";

import { t } from "i18next";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/Basics/MDButton";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import ContactForm from "./ContactForm";

const DialogContact = ({ onClose, onAddContact, contact, isEditMode }) => {
	const [formData, setFormData] = useState({
		firstName: null,
		lastName: null,
		email: "",
		phone: "",
		company: "",
		street: "",
		city: "",
		state: "",
		zip: "",
		country: ""
	});
	const [isFormValid, setIsFormValid] = useState(false);

	useEffect(() => {
		if (contact) setFormData(contact);
	}, [contact]);

	const handleChange = e => {
		const { name, value } = e.target;
		setFormData(prevState => ({
			...prevState,
			[name]: value
		}));
	};

	return (
		<Dialog open>
			<DialogTitle>
				{t("FORMS.contactTitle")}
				<IconButton
					edge="end"
					color="inherit"
					onClick={onClose}
					aria-label="close"
					style={{ float: "right" }}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<ContactForm
					formData={formData}
					onChangeForm={handleChange}
					onUpdateValidateForm={val => setIsFormValid(val)}
				/>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={onClose}>
					{t("SETTINGS.cancel")}
				</MDButton>
				<MDButton
					variant="contained"
					color="info"
					onClick={() => {
						onAddContact({ contact: formData });
						onClose?.();
					}}
					disabled={!isFormValid}
				>
					{t(isEditMode ? "SETTINGS.edit" : "SETTINGS.add")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
};

export default DialogContact;
