import { useMemo } from "react";

const getLabel = (labels, language) => {
	if (!labels || typeof labels !== "object") return "";
	return labels[language] || labels.fr || labels[Object.keys(labels)[0]] || "";
};

const convertObjectToArray = obj => {
	return Object.keys(obj || {}).map(key => ({ ...obj[key], key }));
};

const createNestedStructure = (
	item,
	parentCodes = [],
	parentLabels = [],
	existingKeys = [],
	language
) => {
	const currentCodes = [...parentCodes, item.code];
	const children = convertObjectToArray(item?.items || {});

	const label = getLabel(item.label, language);

	const currentPaths = [...parentLabels, label];

	// If no children, add to existingKeys
	if (!children.length) {
		existingKeys.push({
			variable: currentCodes.join("."),
			value: currentPaths.join(" / ")
		});
	}

	return {
		id: Math.random() * 1000, // Consider using a more robust ID generation
		title: getLabel(item?.label, language),
		value: "",
		child: children.map(childItem =>
			createNestedStructure(childItem, currentCodes, currentPaths, existingKeys, language)
		)
	};
};

const useMapDictionary = (dictionary, language) => {
	return useMemo(() => {
		if (!dictionary) return [];

		const existingKeys = [];
		const mappedDictionary = convertObjectToArray(dictionary).map(item =>
			createNestedStructure(item, [], [], existingKeys, language)
		);

		return {
			mappedDictionary,
			existingKeys
		};
	}, [dictionary]);
};

export default useMapDictionary;
