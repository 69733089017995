import "./MentionList.css";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { FaCaretRight } from "react-icons/fa";

const MenuList = forwardRef((props, ref) => {
	const [hoveredMenu, setHoveredMenu] = useState([]);
	const [selectedMenuItem, setSelectedMenuItem] = useState();

	/**
	 * Sélectionner un élément du menu
	 **/
	const selectItem = item => {
		setSelectedMenuItem(item);
		if (item) {
			props.command({ id: item.title });
		}
	};

	/**
	 * Gérer les mouvements "up" dans le menu
	 **/
	const upHandler = () => {};

	/**
	 * Gérer les mouvements "down" dans le menu
	 **/
	const downHandler = () => {};

	/**
	 * Gérer l'action "Enter"
	 **/
	const enterHandler = () => {
		selectItem(selectedMenuItem);
	};

	useEffect(() => {
		/**
		 * Réinitialiser la sélection au début lorsque les items changent
		 **/
		setSelectedMenuItem(undefined);
	}, [props.items]);

	useImperativeHandle(ref, () => ({
		onKeyDown: ({ event }) => {
			if (event.key === "ArrowUp") {
				upHandler();
				return true;
			}

			if (event.key === "ArrowDown") {
				downHandler();
				return true;
			}

			if (event.key === "Enter") {
				enterHandler();
				return true;
			}

			return false;
		}
	}));

	/**
	 * Rendu récursif des sous-menus
	 **/
	const renderMenuItems = (items, level = 0, parentsItem = []) => {
		return items.map(item => (
			<div
				key={item.id}
				className={`menu-item level-${level}`}
				onMouseEnter={() => {
					const { child } = item;
					if (child && child.length > 0) {
						setHoveredMenu(prev => [...prev, item]);
					}
				}}
				onMouseLeave={() => {
					const removeCurrent = hoveredMenu.filter(menu => {
						return menu.id !== item.id;
					});
					setHoveredMenu(removeCurrent);
				}}
			>
				<button
					onClick={() => {
						if (!item.child || item.child.length === 0) {
							const title = parentsItem.length
								? parentsItem.map(p => p.title).join(" / ") + " / " + item.title
								: item.title;
							props.command({ id: title });
						}
					}}
				>
					<span>{item.title}</span>
					{item.child && item.child.length > 0 && <FaCaretRight />}
				</button>

				{/**
				 * Affichage du sous-menu si l'élément a des enfants et est survolé
				 **/}
				{hoveredMenu.find(value => value.id === item.id)?.id &&
					item.child &&
					item.child.length > 0 && (
						<div className="submenu">
							{/**
							 * Rendu récursif des sous-menus
							 **/}
							{renderMenuItems(item.child, level + 1, [...parentsItem, item])}
						</div>
					)}
			</div>
		));
	};

	return (
		<div className="dropdown-menu">
			{props.items.length ? renderMenuItems(props.items, 0, []) : null}
		</div>
	);
});

export default MenuList;
