import React, { useState, useRef } from "react";

import { t } from "i18next";

import Button from "@mui/material/Button";
import { answerTypes, ANSWERS_CONVERSATION_FORMAT } from "../../../../constants/index";
import DialogContact from "../../DialogContact";
import DialogGoogleMap from "../../GoogleMap/DiaglogGoogleMap/DialogGoogleMap";
import DialogLink from "../../DialogLink";
import DialogSurvey from "../../DialogSurvey";
import FileUploader from "./FileUploader";

const MenuTypology = ({ onTypeChange, fileInputRef, typologyType }) => {
	const [onOpenGoogleMap, setOnOpenGoogleMap] = useState(false);
	const [onOpenDialogContact, setOnOpenDialogContact] = useState(false);
	const [onOpenDialogLink, setOnOpenDialogLink] = useState(false);
	const [onOpenDialogSurvey, setOnOpenDialogSurvey] = useState(false);
	const internalFileInputRef = useRef(null);

	const handleTypeSelect = type => {
		if (type === ANSWERS_CONVERSATION_FORMAT.location) {
			setOnOpenGoogleMap(true);
		}
		if (type === ANSWERS_CONVERSATION_FORMAT.contact) {
			setOnOpenDialogContact(true);
		}
		if (type === ANSWERS_CONVERSATION_FORMAT.link) {
			setOnOpenDialogLink(true);
		}
		if (type === ANSWERS_CONVERSATION_FORMAT.menuyesno) {
			setOnOpenDialogSurvey(true);
		}
		if (type === ANSWERS_CONVERSATION_FORMAT.document) {
			(fileInputRef || internalFileInputRef).current.click();
		}
	};

	return (
		<>
			<div style={{ display: "flex", padding: "20px" }}>
				{answerTypes
					.filter(it => it.type === typologyType)
					.filter(t => t.type !== ANSWERS_CONVERSATION_FORMAT.richtext)
					.map(item => (
						<Button
							key={item.type}
							startIcon={item.icon}
							onClick={() => handleTypeSelect(item.type)}
							sx={{
								borderColor: "#ccc",
								color: "#666",
								borderRadius: "20px",
								padding: "6px 16px",
								marginRight: "10px",
								textTransform: "none",
								fontSize: "14px",
								border: "1px solid #ccc"
							}}
						>
							{t(item.label)}
						</Button>
					))}
			</div>

			<FileUploader
				onTypeChange={onTypeChange}
				fileInputRef={fileInputRef || internalFileInputRef}
			/>

			{onOpenDialogContact && (
				<DialogContact
					onClose={() => setOnOpenDialogContact(false)}
					onAddContact={contact => onTypeChange?.(ANSWERS_CONVERSATION_FORMAT.contact, contact)}
				/>
			)}
			{onOpenGoogleMap && (
				<DialogGoogleMap
					onClose={() => setOnOpenGoogleMap(false)}
					onAddLocation={location => onTypeChange?.(ANSWERS_CONVERSATION_FORMAT.location, location)}
				/>
			)}
			{onOpenDialogLink && (
				<DialogLink
					onClose={() => setOnOpenDialogLink(false)}
					onAddLink={link => onTypeChange?.(ANSWERS_CONVERSATION_FORMAT.link, link)}
				/>
			)}
			{onOpenDialogSurvey && (
				<DialogSurvey
					onClose={() => setOnOpenDialogSurvey(false)}
					onAddSurvey={survey => onTypeChange?.(ANSWERS_CONVERSATION_FORMAT.menuyesno, survey)}
				/>
			)}
		</>
	);
};

export default MenuTypology;
