/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from "react";
import { BlockNoteView } from "@blocknote/mantine";
import { t } from "i18next";
import {
	BlockNoteSchema,
	defaultInlineContentSpecs,
	defaultBlockSpecs,
	locales
} from "@blocknote/core";
import { SuggestionMenuController, useCreateBlockNote } from "@blocknote/react";
import "@blocknote/mantine/style.css";
import "@blocknote/core/fonts/inter.css";

import MDBox from "components/Basics/MDBox";

import useGetImageUploaded from "hooks/useGetImageUploaded";
import useMapDictionary from "hooks/useMapDictionary";

import MenuList from "./MentionMenu/MentionList";
import MentionCodeBox from "./Mention";

const UNWANTED_BLOCKS = [
	"audio",
	"file",
	"video",
	"h3",
	"codeBlock",
	"heading3",
	"checkListItem",
	"emoji",
	"Emoji"
];

const filterBlockSpecs = () => {
	return Object.fromEntries(
		Object.entries(defaultBlockSpecs).filter(([blockName]) => !UNWANTED_BLOCKS.includes(blockName))
	);
};

// Move CustomSuggestionMenu outside the component
const CustomSuggestionMenu = ({
	items = [{ title: "" }],
	loadingState,
	onItemClick,
	editorBlock,
	dictionary
}) => {
	if (loadingState?.includes("loading"))
		return <div>{t("COMPONENT.ANSWERTYPE.BLOCKNOTE.chargement")} </div>;
	if (!items.length) return <div>{t("COMPONENT.ANSWERTYPE.BLOCKNOTE.itemNotfound")}</div>;

	return (
		<MenuList
			items={items}
			editorBlock={editorBlock}
			onItemClick={onItemClick}
			dictionary={dictionary}
		/>
	);
};

const BlockNote = ({ language, dictionary, onSetHtml, value, trigger }) => {
	const { existingKeys } = useMapDictionary(dictionary);
	const schema = BlockNoteSchema.create({
		inlineContentSpecs: { ...defaultInlineContentSpecs, mention: MentionCodeBox },
		blockSpecs: filterBlockSpecs()
	});

	const uploadFile = useGetImageUploaded();

	const editorBlock = useCreateBlockNote({
		sideMenuDetection: "editor",
		schema,
		initialContent: [{ type: "paragraph" }],
		uploadFile,
		dictionary: locales.fr
	});

	const fetchData = async () => {
		let newValue = value || "";

		existingKeys.forEach(keyItem => {
			// Assign the result back to the text property
			newValue = newValue?.replaceAll(
				`{{{ ${keyItem.variable} }}}`,
				`<span class="mention-tag" data-inline-content-type="mention" data-user="${keyItem.value}">${keyItem.value}</span>`
			);
		});
		const newBlock = await editorBlock.tryParseHTMLToBlocks(newValue);
		editorBlock.replaceBlocks(editorBlock.document, newBlock);
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		fetchData();
	}, [trigger, existingKeys]);

	const onChange = async () => {
		const htmlContent = await editorBlock.blocksToHTMLLossy(editorBlock.document);
		onSetHtml(htmlContent);
	};

	return (
		<MDBox className="code-block">
			<BlockNoteView
				editor={editorBlock}
				onChange={onChange}
				lang="fr"
				emojiPicker={false}
				theme="light"
			>
				<SuggestionMenuController
					triggerCharacter="@"
					suggestionMenuComponent={props => (
						<CustomSuggestionMenu
							{...props} // Pass the props from SuggestionMenuController here
							editorBlock={editorBlock} // Pass editorBlock to CustomSuggestionMenu
							dictionary={dictionary}
						/>
					)}
					onItemClick={() => {}}
				/>
			</BlockNoteView>
		</MDBox>
	);
};

export default BlockNote;
